/* Section 1 */
.AboutTopScreen {
  background: #fff;
  min-height: 600px;
  width: 100%;
  padding-top: 80px;
}
.LineDesignDiv {
  background-repeat: no-repeat;
  background-size: contain;
  height: 500px;
  width: 100%;
  background-position-x: right;
}
.CraftingDesign {
  background: rgba(84, 71, 167, 1);
  height: 350px;
  width: 70%;
  margin: auto;
  border-radius: 20px;
  margin-top: -420px;
}
.gradient-text {
  background: -webkit-linear-gradient(
    rgba(254, 212, 248, 1),
    rgba(246, 100, 214, 1)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  padding-top: 30px;
  margin-bottom: 30px;
}
.growcraft-text {
  color: #fff;
  width: 80%;
  margin: auto;
  text-align: center;
}
.growcraft-hr {
  width: 80%;
  height: 2px;
  background: #fff;
  margin: auto;
  margin-top: 50px;
}
.growcraft-para {
  color: #fff;
  text-align: center;
  margin-top: 20px;
}
/* Section 2 */
.AboutTopScreen2 {
  background-image: linear-gradient(
    to bottom,
    rgba(252, 251, 255, 1),
    rgba(241, 230, 255, 1)
  );
  min-height: 500px;
  width: 100%;
  display: grid;
  grid-template-columns: 50% 50%;
}
.AboutTopScreenDiv {
  margin: auto;
}
.WHOWEAREDiv {
  height: 2px;
  width: 10%;
  background: rgba(116, 63, 169, 1);
  margin-top: 20px;
}
.WHOWEAREMainDiv {
  display: flex;
  margin: 30px 0px 10px;
}
.AboutTopScreenDiv1 {
  padding-right: 80px;
}
.WHOWEAREText {
  font-weight: bold;
}
/* Section 3 */
.AboutTopScreen3 {
  background: #fff;
  min-height: 500px;
  width: 100%;
  display: grid;
  grid-template-columns: 50% 50%;
  padding: 50px 100px 20px;
}
.OurMissionDiv2 {
  margin: auto;
}
.OurMissionHeading {
  display: flex;
}
.OurText {
  color: rgba(116, 63, 169, 1);
  margin-right: 10px;
}
.OurMissionBody {
  font-size: 20px;
  font-weight: 500;
}
.OurMissionBody1 {
  color: rgba(45, 55, 72, 1);
  font-size: 16px;
}
.SimformDiv {
  border-left: 2px solid rgba(116, 63, 169, 1);
  padding-left: 20px;
}
.SimformText {
  background: -webkit-linear-gradient(
    rgba(243, 130, 253, 1),
    rgba(129, 44, 164, 1)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 16px;
}
.founderImage {
  border-radius: 25px;
  margin-right: 20px;
}
.founderdiv {
  display: flex;
  margin-top: 10px;
}
.founderText {
  color: rgba(113, 128, 150, 1);
  font-size: 14px;
}
/* Section 4 */
.AboutTopScreen4 {
  background: #fff;
  min-height: 500px;
  width: 100%;
  display: grid;
  grid-template-columns: 50% 50%;
  padding: 0px 100px 20px;
}
.OurValueDiv2 {
  margin-top: 30px;
}
.Ensuringtotalcustomer {
  color: rgba(45, 55, 72, 1);
}
/* Section 5 */
.AboutTopScreen5 {
  background-image: linear-gradient(
    to bottom,
    rgba(252, 251, 255, 1),
    rgba(241, 230, 255, 1)
  );
  min-height: 550px;
  width: 100%;
  position: relative;
}

.slide {
  display: none;
}

.active {
  display: block;
}

.datamap {
  display: grid;
  grid-template-columns: 60% 40%;
  padding: 40px 80px 40px;
}
.FounderCEODiv {
  min-height: 450px;
  width: 80%;
}

.managerdiv {
  min-height: 450px;
  margin: auto;
  margin-top: 30px;
}
.managerdivImage {
  border-top-right-radius: 120px;
}
.FounderCEOLine {
  width: 10%;
  height: 2px;
  background: rgba(116, 63, 169, 1);
  float: left;
  margin-top: 20px;
  margin-right: 10px;
}
.founderName {
  margin: 20px 0px 30px;
}
.founderBody {
  font-size: 16px;
  color: rgba(45, 55, 72, 1);
}
.founderhr {
  height: 2px;
  background: rgba(166, 166, 169, 1);
}
.shareIconMainDiv {
  display: flex;
}
.shareIconDiv {
  height: 50px;
  width: 50px;
  border-radius: 25px;
  background: rgba(61, 131, 217, 1);
  margin-right: 10px;
  text-align: center;
  padding-top: 10px;
}
.iconContainer {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
}

.icon {
  width: 50px;
  height: 10px;
  background-color: rgba(213, 208, 217, 1);
  border-radius: 20px;
  display: inline-block;
  margin: 0 5px;
  cursor: pointer;
  margin-bottom: 50px;
}

.icon.active {
  background-color: rgba(140, 77, 223, 1);
}

.prev1,
.next1 {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -22px;
  font-weight: bold;
  font-size: 18px;
  /* transition: 0.6s ease; */
  border-radius: 0 3px 3px 0;
  user-select: none;
  /* background-color: rgba(0, 0, 0, 0.8); */
  border: none;
  color: rgba(0, 0, 0, 0.5);
}

.next1 {
  right: 0;
  border-radius: 3px 0 0 3px;
}

.prev1:hover,
.next1:hover {
  background-color: rgba(0, 0, 0, 0.4);
}

/* Section 6 */
.AboutTopScreen6 {
  min-height: 600px;
  width: 100%;
  background: rgba(116, 63, 169, 1);
  color: #fff;
  display: grid;
  justify-content: center;
  padding: 50px 80px 50px;
  text-align: center;
}
.linediv {
  height: 2px;
  width: 5%;
  background: #fff;
  margin: auto;
  margin-bottom: 10px;
}
.ourteam {
  margin: auto;
}
.employeedetailsImage{
  height: 80px;
  width: 80px;
  border-radius: 40px;
}
.employeesmapdiv {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  margin-top: 50px;
}
.employeedetails {
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.29),
    rgba(255, 255, 255, 0.39)
  );
  height: 200px;
  width: 200px;
  margin: 20px;
  margin-bottom: 30px;
  border-radius: 30px;
  text-align: center;
}

/* Media query for screen sizes */
@media screen and (min-width: 1px) and (max-width: 600px) {
  /* Section 1 */
  .AboutTopScreen {
    min-height: 430px;
  }
  .CraftingDesign {
    height: 250px;
    width: 90%;
    margin-top: -450px;
  }
  .gradient-text,
  .growcraft-text {
    font-size: 22px;
  }
  .growcraft-hr {
    margin-top: 20px;
  }
  .growcraft-para {
    padding: 0px 45px 0px;
  }
  /* Section 2 */
  .AboutTopScreen2 {
    min-height: 500px;
    padding: 30px 20px 30px;
    grid-template-columns: 100%;
  }
  .AboutTopScreenDivImage {
    height: 200px;
  }
  .WHOWEAREDiv {
    margin-top: 15px;
  }
  .WHOWEAREh2 {
    font-size: 25px;
  }
  .WHOWEAREh4 {
    font-size: 20px;
  }
  .AboutTopScreenDiv1 {
    padding-right: 20px;
  }
  .WHOWEAREpara {
    font-size: 14px;
  }
  /* Section 3 */
  .AboutTopScreen3 {
    min-height: 500px;
    width: 100%;
    padding: 50px 20px 0px;
  }
  .OurText,
  .OurText1 {
    font-size: 16px;
  }
  .OurMissionBody {
    font-size: 18px;
  }
  .OurMissionBody1,
  .SimformText {
    font-size: 12px;
  }

  .founderText1 {
    font-size: 14px;
  }
  .OurMissionDiv2 {
    margin-left: 5px;
    display: grid;
    justify-content: center;
    align-items: center;
  }
  .OurMissionImage {
    height: 380px;
    width: 220px;
  }
  /* Section 4 */
  .AboutTopScreen4 {
    background: #fff;
    min-height: 450px;
    width: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
    padding: 0px 20px 20px;
  }
  .OurValueDiv2 {
    margin-top: 0px;
  }
  .OurMissionImage1 {
    margin-top: 60px;
  }
  /* Section 5 */
  .AboutTopScreen5 {
    min-height: 620px;
  }
  .datamap {
    padding: 40px 20px 0px;
    grid-template-columns: 50% 50%;
  }
  .FounderCEODiv {
    width: 100%;
    margin: 0px 10px 0px;
  }
  .founderRole,
  .founderh3 {
    font-size: 16px;
  }
  .founderName {
    font-size: 18px;
    margin: 10px 0px 10px;
  }
  .founderBody,
  .founderExp {
    font-size: 12px;
  }
  .shareIconDivImage {
    height: 10px;
  }
  .shareIconDiv {
    height: 30px;
    width: 30px;
    padding-top: 0px;
  }
  .managerdivImage {
    height: 300px;
    width: 200px;
    border-top-right-radius: 100px;
  }
  .prev1,
  .next1 {
    background-color: transparent;
  }
  /* Section 6 */
  .ourteam {
    font-size: 16px;
  }
  .ourteamh1 {
    font-size: 20px;
  }
  .ourteamPara {
    font-size: 14px;
  }
  .employeesmapdiv {
    grid-template-columns: 50% 50%;
    margin: 0px;
    padding: 0px;
  }
  .employeedetails {
    width: 150px;
    margin: 20px 0px 0px;
    padding-top: 25px;
  }
  .employeedetailsImage {
    height: 50px;
    
  }
  .employeedetailsh6 {
    font-size: 14px;
  }
  .employeedetailsPara {
    font-size: 12px;
  }
}

@media screen and (min-width: 601px) and (max-width: 800px) {
  /* Section 1 */
  .AboutTopScreen {
    min-height: 470px;
  }
  .CraftingDesign {
    height: 280px;
    width: 90%;
    margin-top: -450px;
  }
  .gradient-text,
  .growcraft-text {
    font-size: 28px;
  }
  .growcraft-hr {
    margin-top: 20px;
  }
  .growcraft-para {
    padding: 0px 45px 0px;
  }
  /* Section 2 */
  .AboutTopScreen2 {
    min-height: 500px;
    padding: 30px 30px 30px;
    grid-template-columns: 100%;
  }
  .AboutTopScreenDivImage {
    height: 250px;
  }
  .WHOWEAREDiv {
    margin-top: 15px;
  }
  .WHOWEAREh2 {
    font-size: 28px;
  }
  .WHOWEAREh4 {
    font-size: 20px;
  }
  .AboutTopScreenDiv1 {
    padding-right: 20px;
  }
  .WHOWEAREpara {
    font-size: 16px;
  }
  /* Section 3 */
  .AboutTopScreen3 {
    min-height: 500px;
    width: 100%;
    padding: 50px 30px 0px;
  }
  .OurText,
  .OurText1 {
    font-size: 22px;
  }
  .OurMissionBody {
    font-size: 18px;
  }
  .OurMissionBody1,
  .SimformText {
    font-size: 16px;
  }
  .founderText1 {
    font-size: 16px;
  }
  .OurMissionDiv1 {
    width: 95%;
    margin: auto;
  }
  .OurMissionDiv2 {
    margin-left: 5px;
    display: grid;
    justify-content: center;
    align-items: center;
  }
  .OurMissionImage {
    height: 500px;
    width: 280px;
  }
  /* Section 4 */
  .AboutTopScreen4 {
    justify-content: center;
    align-items: center;
    padding: 10px 1px 50px 25px;
  }
  .OurValueDiv2 {
    margin-top: 0px;
  }
  /* Section 5 */
  .AboutTopScreen5 {
    min-height: 700px;
  }
  .datamap {
    padding: 40px 30px 0px;
  }
  .FounderCEODiv {
    width: 95%;
    margin: 0px 10px 0px;
  }
  .founderRole,
  .founderh3 {
    font-size: 20px;
  }
  .founderName {
    font-size: 22px;
    margin: 10px 0px 10px;
  }
  .founderBody,
  .founderExp {
    font-size: 15px;
  }
  .shareIconDivImage {
    height: 15px;
  }
  .shareIconDiv {
    height: 40px;
    width: 40px;
    padding-top: 5px;
  }
  .managerdivImage {
    height: 320px;
    width: 200px;
    border-top-right-radius: 100px;
  }
  .prev1,
  .next1 {
    background-color: transparent;
  }
  /* Section 6 */
  .AboutTopScreen6 {
    padding: 50px 50px 50px;
  }
  .employeesmapdiv {
    grid-template-columns: 50% 50%;
    width: 100%;
    padding-left: 0px;
  }
  .employeedetails {
    width: 200px;
    padding-top: 28px;
  }
  .employeedetailsImage {
    height: 40px;
  }
}

@media screen and (min-width: 801px) and (max-width: 1000px) {
  /* Section 1 */
  .AboutTopScreen {
    min-height: 470px;
  }
  .CraftingDesign {
    height: 280px;
    width: 80%;
    margin-top: -460px;
  }
  .growcraft-hr {
    margin-top: 20px;
  }
  .growcraft-para {
    padding: 0px 45px 0px;
  }
  /* Section 2 */
  .AboutTopScreen2 {
    min-height: 500px;
    padding: 30px 20px 30px;
    grid-template-columns: 50% 50%;
  }
  .AboutTopScreenDivImage {
    height: 220px;
  }
  .WHOWEAREDiv {
    margin-top: 15px;
  }
  .WHOWEAREh2 {
    font-size: 25px;
  }
  .WHOWEAREh4 {
    font-size: 20px;
  }
  .AboutTopScreenDiv1 {
    padding-right: 20px;
  }
  .WHOWEAREpara {
    font-size: 14px;
  }
  /* Section 3 */
  .AboutTopScreen3 {
    padding: 50px 30px 20px;
  }
  .OurMissionDiv2 {
    margin: auto;
  }
  .OurMissionHeading {
    display: flex;
  }
  .OurMissionBody {
    font-size: 20px;
  }
  .OurMissionBody1,
  .SimformText {
    font-size: 16px;
  }
  .OurMissionImage {
    height: 500px;
    width: 320px;
  }
  .founderText {
    font-size: 14px;
  }
  /* Section 4 */
  .AboutTopScreen4 {
    min-height: 600px;
    padding: 0px 30px 20px;
  }
  .OurValueDiv2 {
    margin-top: 30px;
  }
  /* Section 5 */
  .AboutTopScreen5 {
    min-height: 700px;
  }
  .datamap {
    padding: 40px 50px 0px;
  }
  .FounderCEODiv {
    width: 95%;
    margin: 0px 10px 0px;
  }
  .managerdivImage {
    height: 320px;
    width: 250px;
    border-top-right-radius: 100px;
  }
  /* Section 6 */
  .AboutTopScreen6 {
    min-height: 650px;
    width: 100%;
    display: grid;
    justify-content: center;
    text-align: center;
  }
  .linediv {
    height: 2px;
    width: 5%;
    margin: auto;
    margin-bottom: 10px;
  }
  .ourteam {
    margin: auto;
  }
  .employeesmapdiv {
    display: grid;
    grid-template-columns: 33.33% 33.33% 33.33%;
    margin-top: 50px;
  }
  .employeedetails {
    height: 200px;
    width: 200px;
    margin-left: 30px;
    margin-bottom: 30px;
    text-align: center;
  }
}

@media screen and (min-width: 1001px) and (max-width: 1200px) {
  /* Section 1 */
  .AboutTopScreen {
    min-height: 500px;
  }
  .CraftingDesign {
    height: 300px;
    width: 80%;
    margin-top: -460px;
  }
  .growcraft-hr {
    margin-top: 20px;
  }
  .growcraft-para {
    padding: 0px 45px 0px;
  }
  /* Section 2 */
  .AboutTopScreen2 {
    min-height: 500px;
    padding: 30px 20px 50px;
    grid-template-columns: 50% 50%;
  }
  .AboutTopScreenDivImage {
    height: 250px;
  }
  .WHOWEAREDiv {
    margin-top: 15px;
  }
  .AboutTopScreenDiv1 {
    padding-right: 20px;
  }
  /* Section 3 */
  .AboutTopScreen3 {
    padding: 50px 50px 20px;
  }
  .OurMissionDiv2 {
    margin: auto;
  }
  .OurMissionHeading {
    display: flex;
  }
  .OurMissionImage {
    height: 430px;
    width: 320px;
  }
  /* Section 4 */
  .AboutTopScreen4 {
    min-height: 500px;
    padding: 0px 30px 80px;
  }
  .OurValueDiv2 {
    margin-top: 30px;
  }
  /* Section 5 */
  .AboutTopScreen5 {
    min-height: 600px;
  }
  .datamap {
    padding: 50px 50px 0px;
  }
  .FounderCEODiv {
    width: 100%;
    margin: 0px 10px 0px;
  }
  /* Section 6 */
}

@media screen and (min-width: 1201px) and (max-width: 1400px) {
  /* Section 1 */
  /* Section 2 */
  /* Section 3 */
  /* Section 4 */
  /* Section 5 */
  /* Section 6 */
}
