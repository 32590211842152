.footerMain {
  background-color: #29254f;
  padding: 50px;
}
.footerTop {
  display: grid;
  grid-template-columns: 25% 35% 40%;
}
.footerTopText {
  font-size: 16px;
  font-weight: 400;
  color: #F2F1F1
  ;
}
.footerTopImg {
  height: 20px;
  width: 20px;
  margin:10px 5px 0px;
}
.footerTopContent {
  float: left;
  padding: 5px;
  /* margin-right: 5px; */
}
.footerTopText1 {
  font-size: 14px;
  font-weight: 400;
  color: #fff;
}
.hrtag{
    height: 3px;
    margin-bottom: 60px;
    background-color: #FFF;
}
.footerBody{
    display: grid;
    grid-template-columns: 32% 3% 22% 3% 15% 25%;
    color: #FFF;
}
.footerBodyLogo{
  height: 50px;
}
.footerbodyText{
    margin-top: 15px;
    font-size: 16px;
    font-weight: 400;
}
.arrowSign{
    height: 10px;
    margin-right: 8px
}
.footerBodytitle{
    margin-top: 18px;
}
.footerBodytitle1{
    color: #FFF;
    text-decoration: none;
}
.footerInput{
    height: 40px;
    border: 0px; 
    width: 90%;
    padding-left: 20px;
    padding-right: 10px;
    border: 0px;
    border-radius: 30px;
}
.footerRightClick{
    border: 0px;
    border-radius: 30px;
    height: 18px;
    width: 18px;
   
}
.footerInputBody{
    width: 85%;
    border-radius: 30px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.footerbuttom{
    margin: 30px 0px 30px;
}
.footerbuttomText{
    text-align: center;
    color: #FFF;
}
.FooterBodyIcon{
  border: 1px solid #FFF;
  width: 30px;
  height: 30px;
  border-radius: 50px;
  float: left;
  margin: 8px;
  text-align: center;
}
.rightClick{
  background: transparent;
  border: 0px;
}

/* Media query for screen sizes between 1px and 600px */
@media screen and (min-width: 1px) and (max-width: 600px) {
  .footerMain {
    padding: 30px;
  }
  .footerTop {
    grid-template-columns: 100%;
  }
  .footerTopContent {
    float: none;
    text-align: center;
    padding: 10px;
  }
  .footerBody{
    grid-template-columns: 100%;
    text-align: center;
  }
  .footerBodyLogo{
    height: 80px;
  }
  .FooterBodyIconDiv{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
  }
  .footerBodytitle{
    margin: 5px 0px 0px;
  }
  .CompanyDiv{
   margin: 30px 0px 30px;
  }
  .ServicesText{
    background: rgba(84, 71, 167, 1);
    padding: 5px 0px 5px;
    border-radius: 20px;
  }
  .footerInputBody{
    width: 50%;
    margin: auto;
    border-radius: 30px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .footerInput{
    margin-right: 20PX;
  }
}

/* Media query for screen sizes between 601px and 800px */
@media screen and (min-width: 601px) and (max-width: 800px) {
  .footerMain {
    padding: 30px;
  }
  .footerTop {
    grid-template-columns: 100%;
  }
 
  .footerBody{
    grid-template-columns: 100%;
    text-align: center;
  }
  
  .footerBodyLogo{
    height: 80px;
  }
  .FooterBodyIconDiv{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
  }
  .footerBodytitle{
    margin: 5px 0px 0px;
  }
  .CompanyDiv{
   margin: 30px 0px 30px;
  }
  .ServicesText{
    background: rgba(84, 71, 167, 1);
    padding: 5px 0px 5px;
    border-radius: 20px;
  }
  .footerInputBody{
    width: 50%;
    margin: auto;
    border-radius: 30px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .footerInput{
    margin-right: 20PX;
  }
}

/* Media query for screen sizes between 801px and 1200px */
@media screen and (min-width: 801px) and (max-width: 1200px) {
  .footerMain {
    padding: 40px;
  }
  .footerTop {
    grid-template-columns: 25% 35% 40%;
  }
  .footerTopText {
    font-size: 16px;
  }
  .footerBody{
    grid-template-columns:32% 3% 22% 3% 15% 25%;
    
  }
  
  .footerBodyLogo{
    height: 50px;
  }
  .FooterBodyIconDiv{
    display: none;
    margin-bottom: 30px;
  }
  .footerBodytitle{
    margin: 10px 0px 0px;
  }
 
  .ServicesText{
    padding: 5px 0px 5px;
    border-radius: 20px;
  }
  .footerInputBody{
    width: 100%;
    border-radius: 30px;
    margin-bottom: 20px;
  }
  .footerInput{
    margin-right: 20PX;
    padding-left: 10px;
  }
}

/* Media query for screen sizes between 1201px and 1300px */
@media screen and (min-width: 1201px) and (max-width: 1300px) {
  .footerTopContent {
    padding: 10px;
  }
}
