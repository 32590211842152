/* Section 1 */

.OurProjectTopScreen {
  background-image: linear-gradient(
    to bottom,
    rgba(252, 251, 255, 1),
    rgba(241, 230, 255, 1)
  );
  min-height: 650px;
  width: 100%;
  display: grid;
  grid-template-columns: 50% 50%;
  padding: 160px 80px 50px;
}
.OurProjectTopScreenDiv1 {
  padding: 40px 80px 20px 0px;
}

.ScreentopImageDiv {
  height: 400px;
  width: 100%;
}
.OurProjectTopScreenh1 {
  width: 80%;
  margin-bottom: 40px;
}
/* Section 2 */

.OurProjectTopScreen2 {
  background: #fff;
  min-height: 500px;
  width: 100%;
  padding: 50px 50px 80px;
  text-align: center;
}
.ExploreLine {
  height: 2px;
  width: 5%;
  background: rgba(116, 63, 169, 1);
  margin: auto;
}
.ExploreText {
  color: rgba(103, 106, 109, 1);
}
.ProjectshowDiv {
  position: relative;
  background: rgba(116, 63, 169, 1);
  min-height: 400px;
  width: 100%;
  margin: auto;
  margin: 50px 0px 0px;
  color: #fff;
  padding: 50px 0px 50px;
  border-radius: 20px;
}
.ProjectshowTitle {
  margin-top: 20px;
}
.ProjectshowBody {
  height: 200px;
  width: 75%;
  margin: auto;
  padding: 20px 0px 50px;
}
/* Add these styles to ProjectStyle.css */

/* Navigation Buttons */
.prev2,
.next2 {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -22px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  /* transition: 0.6s ease; */
  border-radius: 0 3px 3px 0;
  user-select: none;
  /* background-color: rgba(0, 0, 0, 0.8); */
  border: none;
  color: rgba(0, 0, 0, 0.5);
}

.next2 {
  right: 0;
  border-radius: 3px 0 0 3px;
}

.prev2:hover,
.next2:hover {
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
}

/* Dots */
.dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 8px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.active,
.dot:hover {
  background-color: #717171;
}

/* Section 3 */
.OurProjectTopScree3 {
  background: rgba(246, 239, 255, 1);
  min-height: 600px;
  width: 100%;
  padding: 20px 100px 20px;
}
.TeamworkDiv {
  display: flex;
  justify-content: center;
  margin: auto;
  border-radius: 40px;
}
.dot2 {
  margin-left: 95%;
  margin-top: -30px;
}
.dot1 {
  margin-bottom: -30px;
}
.TeamMeetingImage {
  height: 500px;
  width: 80%;
}

/* Media query for screen sizes*/
@media screen and (min-width: 1px) and (max-width: 600px) {
  /* Section 1 */
  .OurProjectTopScreen {
    min-height: 600px;
    grid-template-columns: 60% 40%;
    padding: 130px 20px 50px;
  }
  .OurProjectTopScreenDiv1 {
    padding: 30px 50px 0px 0px;
  }
  .ScreentopImageImage {
    height: 150px;
  }
  .OurProjectTopScreenh1 {
    font-size: 25px;
  }
  .OurProjectTopScreenpara {
    font-size: 14px;
  }
  /* Section 2 */
  .OurProjectTopScreen2 {
    padding: 50px 20px 80px;
  }
  .Exploreh1 {
    font-size: 25px;
  }
  .ProjectshowTitle {
    font-size: 20px;
  }
  .ExploreText,
  .ProjectshowBody {
    font-size: 14px;
  }
  .ProjectshowImage {
    width: 300px;
    height: 150px;
  }
  .ProjectshowBody {
    padding: 10px 0px 50px;
    margin-bottom: 80px;
  }
  /* Section 3 */
  .OurProjectTopScree3 {
    min-height: 400px;
    width: 100%;
    padding: 20px 20px 20px;
  }
  .TeamMeetingImage {
    height: 250px;
  }
  .dot2 {
    margin-left: 87%;
    margin-top: -30px;
  }
}

@media screen and (min-width: 601px) and (max-width: 800px) {
  /* Section 1 */
  .OurProjectTopScreen {
    min-height: 620px;
    grid-template-columns: 60% 40%;
    padding: 140px 30px 50px;
  }
  .OurProjectTopScreenDiv1 {
    padding: 0px 80px 0px 0px;
  }
  .ScreentopImageImage {
    height: 200px;
  }
  .OurProjectTopScreenh1 {
    font-size: 28px;
  }
  .OurProjectTopScreenpara {
    font-size: 16px;
  }
  /* Section 2 */
  .OurProjectTopScreen2 {
    padding: 50px 30px 80px;
  }
  .Exploreh1 {
    font-size: 28px;
  }
  .ProjectshowTitle {
    font-size: 24px;
  }
  .ExploreText,
  .ProjectshowBody {
    font-size: 16px;
  }
  .ProjectshowImage {
    width: 350px;
    height: 180px;
  }
  .ProjectshowBody {
    padding: 10px 0px 50px;
    margin-bottom: 100px;
  }
  /* Section 3 */
  .OurProjectTopScree3 {
    min-height: 420px;
    padding: 20px 20px 20px;
  }
  .TeamMeetingImage {
    height: 330px;
  }
  .dot2 {
    margin-left: 89%;
    margin-top: -30px;
  }
}

@media screen and (min-width: 801px) and (max-width: 1000px) {
  /* Section 1 */
  .OurProjectTopScreen {
    min-height: 600px;
    grid-template-columns: 60% 40%;
    padding: 140px 40px 50px;
  }
  .OurProjectTopScreenDiv1 {
    padding: 30px 80px 0px 0px;
  }
  .ScreentopImageImage {
    height: 250px;
  }
  .OurProjectTopScreenh1 {
    font-size: 30px;
  }
  /* Section 2 */
  .OurProjectTopScreen2 {
    padding: 50px 40px 80px;
  }
  .Exploreh1 {
    font-size: 30px;
  }
  .ProjectshowTitle {
    font-size: 24px;
  }
  .ProjectshowImage {
    width: 400px;
  }
  .ProjectshowBody {
    padding: 20px 0px 50px;
    margin-bottom: 70px;
  }
  /* Section 3 */
  .OurProjectTopScree3 {
    min-height: 450px;
    padding: 20px 40px 20px;
  }
  .TeamMeetingImage {
    height: 430px;
  }
  .dot1 {
    margin-left: 20px;
  }
  .dot2 {
    margin-left: 89%;
    margin-top: -30px;
  }
}

@media screen and (min-width: 1001px) and (max-width: 1200px) {
  /* Section 1 */
  /* Section 2 */
  /* Section 3 */
}

@media screen and (min-width: 1201px) and (max-width: 1400px) {
  /* Section 1 */
  /* Section 2 */
  /* Section 3 */
}


