/* Section 1 */

.CareerTopScreen {
  background-image: linear-gradient(
    to bottom,
    rgba(252, 251, 255, 1),
    rgba(241, 230, 255, 1)
  );
  min-height: 600px;
  width: 100%;
  display: grid;
  grid-template-columns: 50% 50%;
  padding: 120px 80px 50px;
}
.CareerTopScreenDiv1 {
  padding: 0px 80px 20px 0px;
}

.ScreentopImageDiv {
  background-image: linear-gradient(
    to left,
    rgba(221, 199, 249, 1),
    rgba(241, 231, 255, 0.22),
    rgba(243, 233, 255, 0.18),
    rgba(241, 230, 255, 0.19)
  );
  height: 400px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
}
.CareerTopScreenh1 {
  width: 80%;
  margin: 20px 0px 20px;
}

/* Section 2 */

.CareerTopScreen2 {
  background: #fff;
  min-height: 500px;
  width: 100%;
  padding: 50px 50px 0px;
}
.ExploreLine {
  height: 2px;
  width: 5%;
  background: rgba(116, 63, 169, 1);
  margin: auto;
}
.JobsDiv {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}
.Openingh1 {
  color: rgba(140, 77, 223, 1);
  margin-left: 10px;
}
.jobdetailsMainDiv {
  display: grid;
  grid-template-columns: 50% 50%;
}
.jobdetailDiv {
  width: 80%;
  height: 420px;
  box-shadow: 0px 0px 5px rgba(93, 92, 94, 0.19);
  margin: auto;
  padding: 40px;
  border-radius: 20px;
  margin-bottom: 30px;
}
.jobTitle {
  display: flex;
}
.jobTitleImage {
  margin-right: 20px;
}
.jobTitleText {
  font-weight: bold;
  font-size: 16px;
  margin-right: 10px;
}
.jobName {
  margin: 5px 0px 15px;
}
.applybtn {
  background: rgba(140, 77, 223, 1);
  color: #fff;
  padding: 10px 25px 10px;
  border: 0px;
  border-radius: 30px;
}
/* Section 3 */

.ExploreLine1 {
  height: 2px;
  width: 5%;
  background: rgba(116, 63, 169, 1);
  margin: auto;
  margin-top: 50px;
}
.JobsDiv1 {
  display: flex;
  justify-content: center;
}
.careerbody {
  width: 80%;
  margin: auto;
  text-align: center;
  padding: 10px 20px 0px;
}
.WhyWORKWITHGrowCraftSolutionImage {
  height: 700px;
  width: 100%;
}

/* Media query for screen sizes */
@media screen and (min-width: 1px) and (max-width: 600px) {
  /* Section 1 */
  .CareerTopScreen {
    min-height: 580px;
    padding: 130px 20px 30px;
  }
  .CareerTopScreenDiv1 {
    padding: 0px;
  }
  .CareerIconImage {
    height: 180px;
    width: 220px;
    padding-left: 20px;
  }
  .CareerTopScreenh1 {
    width: 100%;
  }
  .CareerTopScreenh11 {
    font-size: 20px;
  }
  .CareerTopScreenh2 {
    font-size: 18px;
  }
  .CareerTopScreenh5 {
    font-size: 16px;
  }
  .CareerTopScreenPara {
    font-size: 12px;
  }
  /* Section 2 */
  .CareerTopScreen2 {
    padding: 50px 20px 0px;
  }
  .jobdetailDiv {
    width: 95%;
    height: 480px;
    box-shadow: 0px 0px 5px rgba(93, 92, 94, 0.19);
    margin: auto;
    padding: 40px 20px 40px;
    border-radius: 20px;
    margin-bottom: 30px;
  }
  .jobTitleh3 {
    font-size: 18px;
  }
  .jobName {
    font-size: 16px;
  }
  .jobTitleText,
  .jobTitlePara {
    font-size: 14px;
  }
  /* Section 3 */
  .Jobsh1,
  .Openingh1 {
    font-size: 25px;
  }
  .careerbody {
    font-size: 14px;
  }
  .WhyWORKWITHGrowCraftSolutionImage {
    height: 350px;
  }
}

@media screen and (min-width: 601px) and (max-width: 800px) {
  /* Section 1 */
  .CareerTopScreen {
    min-height: 580px;
    padding: 130px 30px 30px;
  }
  .CareerTopScreenDiv1 {
    padding: 0px;
  }
  .ScreentopImageDiv {
    justify-content: right;
    align-items: normal;
    padding-right: 50px;
  }
  .CareerIconImage {
    height: 200px;
    width: 280px;
    padding-left: 50px;
  }
  .CareerTopScreenh1 {
    width: 100%;
  }
  .CareerTopScreenh11 {
    font-size: 25px;
  }
  .CareerTopScreenh2 {
    font-size: 20px;
  }
  .CareerTopScreenh5 {
    font-size: 18px;
  }
  .CareerTopScreenPara {
    font-size: 14px;
  }
  /* Section 2 */
  .CareerTopScreen2 {
    padding: 50px 20px 0px;
  }
  .jobdetailDiv {
    width: 95%;
    height: 480px;
    box-shadow: 0px 0px 5px rgba(93, 92, 94, 0.19);
    margin: auto;
    padding: 40px 30px 40px;
    border-radius: 20px;
    margin-bottom: 30px;
  }
  .jobTitleh3 {
    font-size: 20px;
  }
  .jobName {
    font-size: 18px;
  }
  .jobTitleText,
  .jobTitlePara {
    font-size: 16px;
  }
  /* Section 3 */
  .Jobsh1,
  .Openingh1 {
    font-size: 28px;
  }
  .careerbody {
    font-size: 16px;
  }
  .WhyWORKWITHGrowCraftSolutionImage {
    height: 420px;
  }
}

@media screen and (min-width: 801px) and (max-width: 1000px) {
  /* Section 1 */
  .CareerTopScreen {
    min-height: 600px;
    padding: 130px 40px 30px;
  }
  .CareerTopScreenDiv1 {
    padding: 0px;
  }
  .ScreentopImageDiv {
    justify-content: right;
    align-items: normal;
    padding-right: 50px;
  }
  .CareerIconImage {
    height: 250px;
    width: 350px;
    padding-left: 50px;
  }
  .CareerTopScreenh1 {
    width: 100%;
  }
  .CareerTopScreenh11 {
    font-size: 28px;
  }
  .CareerTopScreenh2 {
    font-size: 23px;
  }
  .CareerTopScreenh5 {
    font-size: 20px;
  }
  .CareerTopScreenPara {
    font-size: 16px;
  }
  /* Section 2 */
  .CareerTopScreen2 {
    padding: 50px 40px 0px;
  }
  .jobdetailDiv {
    width: 95%;
    height: 420px;
    box-shadow: 0px 0px 5px rgba(93, 92, 94, 0.19);
    margin: auto;
    padding: 40px 30px 40px;
    border-radius: 20px;
    margin-bottom: 30px;
  }
  /* Section 3 */
  .Jobsh1,
  .Openingh1 {
    font-size: 30px;
  }
  .WhyWORKWITHGrowCraftSolutionImage {
    height: 580px;
  }
}

@media screen and (min-width: 1001px) and (max-width: 1200px) {
  /* Section 1 */
  .CareerTopScreen {
    min-height: 600px;
    padding: 140px 50px 30px;
  }
  .CareerTopScreenDiv1 {
    padding: 0px;
  }
  .ScreentopImageDiv {
    justify-content: right;
    align-items: normal;
    padding-right: 50px;
  }
  .CareerIconImage {
    height: 300px;
    width: 420px;
    padding-left: 50px;
  }
  .CareerTopScreenh1 {
    width: 100%;
  }
  .CareerTopScreenh11 {
    font-size: 30px;
  }
  .CareerTopScreenh2 {
    font-size: 26px;
  }
  .CareerTopScreenh5 {
    font-size: 22px;
  }
  /* Section 2 */
  /* Section 3 */
  .Jobsh1,
  .Openingh1 {
    font-size: 30px;
  }
  .WhyWORKWITHGrowCraftSolutionImage {
    height: 650px;
  }
}

@media screen and (min-width: 1201px) and (max-width: 1400px) {
  /* Section 1 */
  /* Section 2 */
  /* Section 3 */
}
