/* Section 1 */

.ServiceTopScreen {
  background-image: linear-gradient(
    to bottom,
    rgba(252, 251, 255, 1),
    rgba(241, 230, 255, 1)
  );
  min-height: 650px;
  width: 100%;
  display: grid;
  grid-template-columns: 50% 50%;
  padding: 160px 80px 50px;
}
.ServiceTopScreenDiv1 {
  padding: 80px 80px 20px 0px;
}
.Workpara {
  color: rgba(103, 106, 109, 1);
}
.ScreentopImageDiv {
  background-image: linear-gradient(
    to left,
    rgba(221, 199, 249, 1),
    rgba(241, 231, 255, 0.22),
    rgba(243, 233, 255, 0.18),
    rgba(241, 230, 255, 0.19)
  );
  height: 400px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
}
/* Section 2 */
.ServiceTopScreen2 {
  background: #fff;
  min-height: 1000px;
  width: 95%;
  margin: auto;
  padding: 50px 50px 80px;
  text-align: center;
}
.ExploreLine {
  height: 2px;
  width: 5%;
  background: rgba(116, 63, 169, 1);
  margin: auto;
}
.ExploreText {
  color: rgba(103, 106, 109, 1);
}
.UIUXDesigning {
  display: grid;
  grid-template-columns: 60% 40%;
  margin: 50px 40px 10px;
}
.UIUXDesigningDiv1 {
  padding: 10px 10px 10px 10px;
  text-align: left;
}
.UIUXDesigningDiv11{
  padding-right: 80 
}
.UIUXDesigningDiv2 {
  height: 350px;
  width: 95%;
  display: flex;
  justify-content: right;
  align-items: center;
}
.UIUXDesigningText {
  color: rgba(103, 106, 109, 1);
  font-size: 16px;
}
li {
  color: rgba(140, 77, 223, 1);
}
.DesignText {
  color: #000;
  font-size: 14px;
}
.readmorebutton {
  background: rgba(140, 77, 223, 1);
  padding: 10px 25px 10px;
  border-radius: 30px;
  color: #fff;
  border: 0px;
  margin-top: 20px;
}
.MobileApp {
  display: grid;
  grid-template-columns: 40% 60%;
  margin: 50px 0px 10px;
}
.contactusbtn {
  border: 1px solid rgba(140, 77, 223, 1);
  color: #000;
  padding: 10px 25px 10px;
  background: #fff;
  border-radius: 30px;
}

/* Media query for screen sizes*/
@media screen and (min-width: 1px) and (max-width: 600px) {
  /* Section 1 */
  .ServiceTopScreen {
    min-height: 580px;
    padding: 120px 20px 0px;
  }
  .ServiceTopScreenDiv1 {
    padding: 50px 80px 20px 20px;
  }
  .ScreentopImage {
    height: 250px;
  }
  .Workh1 {
    font-size: 25px;
  }
  .ScreentopImageDiv {
    padding-right: 50px;
  }
  /* Section 2 */
  .ServiceTopScreen2 {
    padding: 50px 0px 80px;
  }
  .Exploreh1 {
    font-size: 22px;
  }
  .ExploreText {
    font-size: 14px;
  }

  .UIUXDesigning {
    grid-template-columns: 100%;
    margin: 50px 30px 50px;
    background: rgba(140, 77, 223, 1);
    color: #fff;
    border-radius: 30px;
  }
  .UIUXDesigningDiv1 {
    padding: 30px 10px 30px 30px;
    width: 95%;
  }
  .UIUXDesigningDiv2 {
    margin: 0px 0px 30px;
    width: 100%;
    justify-content: center;
  }
  .UIUXDesigningText {
    color: #fff;
  }
  li {
    color: #fff;
  }
  .DesignText {
    color: #fff;
  }
  .readmorebutton {
    border: 2px solid #fff;
  }
  .MobileApp {
    grid-template-columns: 100%;
    margin: 50px 30px 10px;
    background: rgba(140, 77, 223, 1);
    color: #fff;
    border-radius: 30px;
    padding-top: 30px;
  }
  .contactusbtn {
    border: 1px solid rgba(140, 77, 223, 1);
    color: #000;
    padding: 10px 25px 10px;
    background: #fff;
    border-radius: 30px;
  }
}

@media screen and (min-width: 601px) and (max-width: 800px) {
  /* Section 1 */
  .ServiceTopScreen {
    min-height: 580px;
    padding: 120px 40px 0px;
  }
  .ServiceTopScreenDiv1 {
    padding: 50px 80px 20px 20px;
  }
  .ScreentopImage {
    height: 280px;
  }
  .ScreentopImageDiv {
    padding-right: 100px;
  }
  /* Section 2 */
  .ServiceTopScreen2 {
    padding: 50px 0px 80px;
  }
  .Exploreh1 {
    font-size: 22px;
  }
  .ExploreText {
    font-size: 14px;
  }

  .UIUXDesigning {
    grid-template-columns: 100%;
    margin: 50px 30px 50px;
    background: rgba(140, 77, 223, 1);
    color: #fff;
    border-radius: 30px;
  }
  .UIUXDesigningDiv1 {
    padding: 30px 10px 30px 30px;
    width: 95%;
  }
  .UIUXDesigningDiv2 {
    margin: 0px 0px 30px;
    width: 100%;
    justify-content: center;
  }
  .UIUXDesigningText {
    color: #fff;
  }
  li {
    color: #fff;
  }
  .DesignText {
    color: #fff;
  }
  .readmorebutton {
    border: 2px solid #fff;
  }
  .MobileApp {
    grid-template-columns: 100%;
    margin: 50px 30px 10px;
    background: rgba(140, 77, 223, 1);
    color: #fff;
    border-radius: 30px;
    padding-top: 30px;
  }
  .contactusbtn {
    border: 1px solid rgba(140, 77, 223, 1);
    color: #000;
    padding: 10px 25px 10px;
    background: #fff;
    border-radius: 30px;
  }
}

@media screen and (min-width: 801px) and (max-width: 1000px) {
  /* Section 1 */
  .ServiceTopScreen {
    min-height: 600px;
    padding: 130px 50px 0px;
  }
  .ServiceTopScreenDiv1 {
    padding: 50px 80px 20px 20px;
  }
  .ScreentopImage {
    height: 320px;
  }
  .ScreentopImageDiv {
    padding-right: 100px;
  }
  /* Section 2 */
  .ServiceTopScreen2 {
    padding: 50px 30px 80px;
    text-align: center;
  }
  .UIUXDesigning {
    display: grid;
    grid-template-columns: 60% 40%;
    margin:50px 0px 50px;
  }
  .UIUXDesigningDiv1 {
    padding:0px 50px 0px 0px ;
    text-align: left;
  }
  .UIUXDesigningDiv11{
    margin-left: 80px;
  }
  .UIUXDesigningDiv2 {
    width: 100%;
  }
  .MobileApp {
    display: grid;
    grid-template-columns: 40% 60%;
    margin: 30px 0px 10px;
  }
}

@media screen and (min-width: 1001px) and (max-width: 1200px) {
/* Section 1 */
.ScreentopImageDiv {
  padding-right: 100px;
}
/* Section 2 */
.ServiceTopScreen2 {
  padding: 50px 50px 80px;
  text-align: center;
}
.UIUXDesigning {
  display: grid;
  grid-template-columns: 60% 40%;
  margin:50px 0px 50px;
}
.UIUXDesigningDiv1 {
  padding:0px 50px 0px 0px ;
}
.UIUXDesigningDiv11{
  margin-left: 50px;
}
}

@media screen and (min-width: 1201px) and (max-width: 1400px) {
/* Section 1 */
/* Section 2 */
}
