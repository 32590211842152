/* Section 1 */

.EcommerceTopScreen {
  min-height: 500px;
  background-repeat: no-repeat;
  background-size: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 60% 40%;
  padding: 120px 80px 50px;
  color: #fff;
  align-items: center;
  justify-content: space-between;
}
.Ecommerceh5 {
  margin: 20px 0px 20px;
}
.EcommerceTopScreenDiv2 {
  text-align: center;
}
.Letstalkbtn {
  background: #fff;
  padding: 5px 20px 5px;
  color: #000;
  border: 0px;
  border-radius: 30px;
  margin-top: 20px;
}
/* Section 2 */
.EcommerceTopScreen2 {
  background: #fff;
  min-height: 450px;
  width: 100%;
  padding: 10px;
}
.CircleDot {
  display: flex;
  justify-content: space-between;
}
.CircleDivDiv {
  height: 80px;
  width: 80px;
  background-image: linear-gradient(
    to right,
    rgba(154, 242, 242, 1),
    rgba(253, 240, 255, 1)
  );
  border-radius: 40px;
}
.EcommerceTopScreen2Contact {
  padding: 0px 70px 50px;
  text-align: center;
}
.LineDiv {
  height: 3px;
  width: 5%;
  background: rgba(116, 63, 169, 1);
  margin: auto;
  margin-top: -50px;
}
.ECOMMERCEWEBSITEDiv1 {
  display: flex;
  justify-content: center;
}
.ECOMMERCEWEBSITEh1 {
  color: rgba(116, 63, 169, 1);
  margin-bottom: 30px;
  margin-left: 10px;
}
.ECOMMERCEWEBSITEul {
  text-align: left;
  color: #000;
  margin: 10px 0px 20px;
}
.WhyChooseOurEcommerceMainDiv {
  display: grid;
  grid-template-columns: auto auto auto;
}
.WhyChooseOurEcommerceDiv {
  padding: 20px 40px 0px 0px;
}
.WhyChooseOurEcommerceImage {
  height: 50px;
  width: 50px;
  background: rgba(116, 63, 169, 1);
  padding: 15px;
  border-radius: 10px;
}
.WhyChooseOurEcommerceh4 {
  margin: 10px 0px 10px;
}
.ReadyMainDiv {
  height: 300px;
  width: 100%;
  border-radius: 20px;
  background: rgba(230, 230, 243, 1);
  margin: 50px 0px 20px;
  display: grid;
  grid-template-columns: 60% 40%;
  justify-content: center;
  align-items: center;
}
.ReadyDiv1 {
  padding: 0px 50px 0px;
}
.ContactBtn {
  padding: 10px 30px 10px;
  background-color: rgba(116, 63, 169, 1);
  border-radius: 30px;
  border: 0px;
  color: #fff;
  margin-top: 20px;
}

/* / Media query for screen sizes / */
@media screen and (min-width: 1px) and (max-width: 600px) {
  /* Section 1 */
  .EcommerceTopScreen {
    min-height: 600px;
    background-size: 280%;
    padding: 100px 20px 50px;
  }
  .Ecommerceh1 {
    font-size: 25px;
  }
  .Ecommerceh5 {
    font-size: 18px;
  }
  .EcommercePara {
    font-size: 14px;
  }
  .EcommerceTopImage {
    height: 150px;
  }
  /* Section 2 */
  .DotDot {
    height: 40px;
  }
  .CircleDivDiv {
    height: 50px;
    width: 50px;
  }
  .ECOMMERCEWEBSITEDiv1 {
    display: grid;
  }
  .ECOMMERCEh1 {
    font-size: 22px;
  }
  .ECOMMERCEWEBSITEh1 {
    margin-left: 0px;
  }
  .EcommerceTopScreen2Contact {
    padding: 0px 20px 50px;
  }
  .ECOMMERCEWEBSITEul {
    font-size: 14px;
  }
  .WhyChooseOurEcommerceh3 {
    font-size: 22px;
    margin-bottom: 50px;
  }
  .WhyChooseOurEcommerceMainDiv {
    grid-template-columns: auto;
  }
  .WhyChooseOurEcommerceDiv {
    border: 2px solid rgba(116, 63, 169, 1);
    margin-bottom: 20px;
    border-radius: 30px;
    padding: 30px 20px 30px;
  }
  .ReadyMainDiv {
    height: 500px;
    padding: 30px 0px 30px;
    grid-template-columns: 100%;
  }
  .ContactBtn {
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 601px) and (max-width: 800px) {
  /* Section 1 */
  .EcommerceTopScreen {
    min-height: 600px;
    background-size: 250%;
    padding: 100px 30px 50px;
  }
  .Ecommerceh1 {
    font-size: 28px;
  }
  .Ecommerceh5 {
    font-size: 20px;
  }
  .EcommercePara {
    font-size: 16px;
  }
  .EcommerceTopImage {
    height: 200px;
  }
  /* Section 2 */
  .DotDot {
    height: 50px;
  }
  .CircleDivDiv {
    height: 60px;
    width: 60px;
  }
  .ECOMMERCEWEBSITEDiv1 {
    display: grid;
  }
  .ECOMMERCEh1 {
    font-size: 25px;
  }
  .EcommerceTopScreen2Contact {
    padding: 0px 30px 50px;
  }
  .ECOMMERCEWEBSITEul {
    font-size: 16px;
  }
  .WhyChooseOurEcommerceh3 {
    font-size: 25px;
    margin-bottom: 50px;
  }
  .WhyChooseOurEcommerceMainDiv {
    grid-template-columns: auto;
  }
  .WhyChooseOurEcommerceDiv {
    border: 2px solid rgba(116, 63, 169, 1);
    margin-bottom: 20px;
    border-radius: 30px;
    padding: 30px 20px 30px;
  }
  .ReadyMainDiv {
    height: 500px;
    padding: 30px 0px 30px;
    grid-template-columns: 100%;
  }
  .ContactBtn {
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 801px) and (max-width: 1000px) {
  /* Section 1 */
  .EcommerceTopScreen {
    min-height: 500px;
    background-size: 180%;
    padding: 100px 50px 50px;
  }
  .Ecommerceh1 {
    font-size: 30px;
  }
  .Ecommerceh5 {
    font-size: 22px;
  }
  .EcommerceTopImage {
    height: 280px;
  }
  /* Section 2 */
  .DotDot {
    height: 60px;
  }
  .CircleDivDiv {
    height: 70px;
    width: 70px;
  }
  .ECOMMERCEWEBSITEDiv1 {
    display: grid;
  }
  .ECOMMERCEh1 {
    font-size: 28px;
  }
  .EcommerceTopScreen2Contact {
    padding: 0px 50px 50px;
  }
  .WhyChooseOurEcommerceh3 {
    font-size: 28px;
    margin-bottom: 30px;
  }
  .WhyChooseOurEcommerceMainDiv {
    grid-template-columns: auto auto;
  }
  .WhyChooseOurEcommerceDiv {
    margin-bottom: 20px;
    padding: 30px 20px 30px;
  }
  .ReadyMainDiv {
    height: 400px;
    grid-template-columns: 60% 40%;
  }
  .Readyh3 {
    font-size: 25px;
  }
  .ReadyImage1 {
    height: 130px;
  }
}

@media screen and (min-width: 1001px) and (max-width: 1200px) {
  /* Section 1 */
  .EcommerceTopScreen {
    min-height: 500px;
    background-size: 150%;
    padding: 120px 65px 50px;
  }
  .EcommerceTopImage {
    height: 320px;
  }
  /* Section 2 */
  .ECOMMERCEh1 {
    font-size: 30px;
  }
  .EcommerceTopScreen2Contact {
    padding: 0px 65px 50px;
  }
  .WhyChooseOurEcommerceh3 {
    margin-bottom: 30px;
  }
  .ReadyMainDiv {
    height: 300px;
    grid-template-columns: 60% 40%;
  }
  .Readyh3 {
    font-size: 25px;
  }
  .ReadyImage1 {
    height: 170px;
  }
}

@media screen and (min-width: 1201px) and (max-width: 1400px) {
  /* Section 1 */
  /* Section 2 */
}
