.navHeading {
  display: flex;
  justify-content: space-between;
}
.contactusDiv {
  border: 1px solid #8c4ddf;
  padding: 0px 20px 0px;
  border-radius: 50px;
  display: flex;
  justify-content: space-evenly;
}
.contactusText1 {
  font-size: 20px;
  color: #000;
  text-decoration: none;
  padding: 5px 10px 5px;
  font-weight: 400;
}
#HeaderBar{
  background: #fff;
  padding: 15px 0px 15px;
  display: flex;
  justify-content: space-around;
  box-shadow: 0px 0px 10px rgb(156, 111, 216);
}

.contactusText1.active {
  color: rgba(140, 77, 223, 1); /* Color for active link */
  background: transparent;
  font-size: 20px;
  padding-top: 0px;
  font-weight: 500;
}