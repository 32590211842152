/* Section 1 */

.FullStackTopScreen {
  min-height: 600px;
  background-repeat: no-repeat;
  background-size: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 60% 40%;
  padding: 100px 80px 50px;
  color: #fff;
  align-items: center;
  justify-content: space-between;
}
.FullStackh5 {
  margin: 20px 0px 20px;
}
.FullStackTopScreenDiv2 {
  text-align: center;
}
.Letstalkbtn {
  background: #fff;
  padding: 5px 20px 5px;
  color: #000;
  border: 0px;
  border-radius: 30px;
  margin-top: 20px;
}
/* Section 2 */
.FullStackTopScreen2 {
  background: #fff;
  min-height: 450px;
  width: 100%;
  padding: 10px;
}
.CircleDot {
  display: flex;
  justify-content: space-between;
}
.CircleDivhi {
  height: 80px;
  width: 80px;
  background-image: linear-gradient(
    to right,
    rgba(154, 242, 242, 1),
    rgba(253, 240, 255, 1)
  );
  border-radius: 40px;
}
.FullStackTopScreen2Contact {
  padding: 0px 70px 50px;
  text-align: center;
}
.Line11 {
  height: 3px;
  width: 5%;
  background: rgba(116, 63, 169, 1);
  margin: auto;
  margin-top: -50px;
}
.ECOMMERCEDiv {
  display: flex;
  justify-content: center;
}
.DEVELOPMENTh1 {
  color: rgba(116, 63, 169, 1);
  margin-bottom: 30px;
  margin-left: 10px;
}
.DEVELOPMENTMainDiv {
  display: grid;
  grid-template-columns: 50% 50%;
}
.DEVELOPMENTDiv {
  width: 90%;
  background: rgba(140, 77, 223, 0.05);
  margin: 20px 20px 10px;
  border-left: 4px solid rgba(140, 77, 223, 0.46);
  box-shadow: 0px 0px 10px rgba(151, 71, 255, 0.11);
  padding: 20px 20px 10px;
  text-align: left;
  display: flex;
  border-radius: 10px;
}
.DEVELOPMENTIndex {
  color: rgba(236, 233, 242, 1);
  padding: 0px 10px 0px;
}
/* Section 3 */
.FullStackTopScreen3 {
  background-image: linear-gradient(
    to bottom,
    rgba(252, 251, 255, 1),
    rgba(241, 230, 255, 1)
  );
  min-height: 550px;
  width: 100%;
  padding: 50px 80px 50px;
  text-align: center;
}
.Line12 {
  height: 3px;
  width: 5%;
  background: rgba(116, 63, 169, 1);
  margin: auto;
}
.FullStackDevelopmentMaindiv {
  display: grid;
  grid-template-columns: 50% 50%;
  margin: 50px 0px 30px;
}
.FullStackDevelopmentImageDiv {
  display: flex;
  justify-content: center;
  align-items: center;
}
.whychooseDiv {
  display: flex;
  text-align: left;
}
.whychooseInnerDiv1 {
  margin-right: 20px;
}
.whychooseInnerDiv2 {
}
.whychooseText {
  color: rgba(103, 106, 109, 1);
}
/* Section 4 */
.FullStackTopScreen4 {
  background: #fff;
  min-height: 400px;
  width: 100%;
  padding: 20px 80px 20px;
}
.GreatTogetherDiv {
  height: 320px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: 100%;
  color: #fff;
  border-radius: 100px;
  text-align: center;
  padding: 50px 80px 50px;
  margin: 50px 0px 50px;
}
.GreatTogetherDivh1 {
  display: flex;
  justify-content: center;
  align-self: center;
  margin: 10px 0px 20px;
}
.ContactUsBtn {
  padding: 10px 30px 10px;
  background-color: rgba(116, 63, 169, 1);
  border-radius: 30px;
  border: 0px;
  color: #fff;
}

/* / Media query for screen sizes / */
@media screen and (min-width: 1px) and (max-width: 600px) {
  /* Section 1 */
  .FullStackTopScreen {
    min-height: 580px;
    background-size: 250%;
    padding: 120px 20px 50px;
  }
  .FullStackh1 {
    font-size: 20px;
  }
  .FullStackh5 {
    font-size: 16px;
  }
  .FullStackPara {
    font-size: 14px;
  }
  .FullStackTopImage {
    height: 150px;
  }
  /* Section 2 */
  .Dot11 {
    height: 40px;
  }
  .CircleDivhi {
    height: 50px;
    width: 50px;
  }
  .FullStackTopScreen2Contact {
    padding: 0px 20px 50px;
    text-align: center;
  }
  .FullStackServicesH1 {
    font-size: 25px;
  }
  .ECOMMERCEDiv {
    display: grid;
  }
  .Line11,
  .Line12 {
    width: 15%;
    margin-top: -20px;
    margin-bottom: 10px;
  }
  .DEVELOPMENTMainDiv {
    grid-template-columns: 100%;
  }
  .DEVELOPMENTh5 {
    font-size: 18px;
  }
  .DEVELOPMENTPara {
    font-size: 14px;
  }
  /* Section 3 */
  .FullStackTopScreen3 {
    padding: 50px 20px 50px;
  }
  .Servicesh12 {
    font-size: 22px;
  }
  .whychooseText {
    font-size: 14px;
  }
  .FullStackDevelopmentImage {
    height: 180px;
  }
  /* Section 4 */
  .FullStackTopScreen4 {
    padding: 20px 20px 20px;
  }
  .GreatTogetherDiv {
    height: 550px;
    background-size: 400%;
  }
}

@media screen and (min-width: 601px) and (max-width: 800px) {
  /* Section 1 */
  .FullStackTopScreen {
    min-height: 580px;
    background-size: 180%;
    padding: 100px 30px 50px;
  }
  .FullStackh1 {
    font-size: 25px;
  }
  .FullStackh5 {
    font-size: 18px;
  }
  .FullStackTopImage {
    height: 180px;
  }
  /* Section 2 */
  .Dot11 {
    height: 50px;
  }
  .CircleDivhi {
    height: 60px;
    width: 60px;
  }
  .FullStackTopScreen2Contact {
    padding: 0px 30px 50px;
  }
  .FullStackServicesH1 {
    font-size: 28px;
  }
  .ECOMMERCEDiv {
    display: grid;
  }
  .Line11,
  .Line12 {
    width: 12%;
    margin-top: -10px;
    margin-bottom: 10px;
  }
  .DEVELOPMENTMainDiv {
    grid-template-columns: 100%;
  }
  /* Section 3 */
  .FullStackTopScreen3 {
    padding: 50px 30px 50px;
  }
  .Servicesh12 {
    font-size: 25px;
  }
  .FullStackDevelopmentImage {
    height: 220px;
  }
  /* Section 4 */
  .FullStackTopScreen4 {
    padding: 20px 30px 20px;
  }
  .GreatTogetherDiv {
    height: 450px;
    background-size: 300%;
  }
}

@media screen and (min-width: 801px) and (max-width: 1000px) {
  /* Section 1 */
  .FullStackTopScreen {
    min-height: 580px;
    background-size: 150%;
    padding: 90px 40px 50px;
  }
  .FullStackh1 {
    font-size: 28px;
  }
  .FullStackTopImage {
    height: 250px;
  }
  /* Section 2 */
  .FullStackTopScreen2Contact {
    padding: 0px 40px 50px;
  }
  .ECOMMERCEDiv {
    display: grid;
  }
  .Line11,
  .Line12 {
    width: 12%;
    margin-top: -20px;
    margin-bottom: 10px;
  }
  /* Section 3 */
  .FullStackTopScreen3 {
    padding: 50px 40px 50px;
  }
  .FullStackDevelopmentImage {
    height: 300px;
  }
  /* Section 4 */
  .FullStackTopScreen4 {
    padding: 20px 40px 20px;
  }
  .GreatTogetherDiv {
    height: 400px;
    background-size: 180%;
  }
}

@media screen and (min-width: 1001px) and (max-width: 1200px) {
  /* Section 1 */
  .FullStackTopScreen {
    min-height: 580px;
    background-size: 120%;
    padding: 90px 60px 50px;
  }
  .FullStackTopImage {
    height: 300px;
  }
  /* Section 2 */
  .FullStackTopScreen2Contact {
    padding: 0px 60px 50px;
  }
  /* Section 3 */
  .FullStackTopScreen3 {
    padding: 50px 60px 50px;
  }
  /* Section 4 */
  .FullStackTopScreen4 {
    padding: 20px 60px 20px;
  }
  .GreatTogetherDiv {
    height: 330px;
    background-size: 120%;
  }
}

@media screen and (min-width: 1201px) and (max-width: 1400px) {
  /* Section 1 */
  /* Section 2 */
  /* Section 3 */
  /* Section 4 */
}
