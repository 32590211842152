/* Section 1 */

.HomeTopScreen {
  background-image: linear-gradient(
    to bottom,
    rgba(252, 251, 255, 1),
    rgba(241, 230, 255, 1)
  );
  height: 630px;
  width: 100%;
  padding-top: 80px;
}
.HomeTopCircle {
  background-image: linear-gradient(
    to top,
    rgba(154, 242, 242, 1),
    rgba(253, 240, 255, 1)
  );
  height: 80px;
  width: 80px;
  border-radius: 40px;
}
.HomeBottomCircle {
  background-image: linear-gradient(
    to top,
    rgba(242, 183, 253, 1),
    rgba(253, 240, 255, 1)
  );
  height: 80px;
  width: 80px;
  border-radius: 40px;
  float: right;
  /* margin-bottom: 10%; */
}
.HomeBody {
  display: grid;
  grid-template-columns: 55% 45%;
  margin: 0px 80px 0px;
}
.HomeBodyText {
  color: #000;
  font-size: 60px;
  font-weight: 500;
  margin-top: -30px;
}
.HomeBodyText1 {
  color: rgba(140, 77, 223, 1);
  font-size: 60px;
  font-weight: 500;
  margin-top: -40px;
}
.HomeBodyText2 {
  color: rgba(117, 115, 115, 1);
  font-size: 20px;
  font-weight: 500;
}
.HomeBodyButtongrid {
  display: flex;
  flex-direction: row;
}
.HomeBodyButton {
  margin-right: 40px;
  background: rgba(140, 77, 223, 1);
  border-radius: 40px;
  padding: 10px 30px 10px;
  text-decoration: none;
}
.HomeBodyButton1 {
  background: #fff;
  border-radius: 40px;
  padding: 10px 30px 10px;
  border: 1px solid rgba(140, 77, 223, 1);
  text-decoration: none;
}
.HomeBodyButtonText {
  color: #fff;
  font-weight: 500;
}
.HomeBodyButtonText1 {
  color: #000;
  font-weight: 500;
}
.HomeBodyTrusted {
  color: #000;
  font-weight: 500;
  margin-top: 30px;
}
.HomeBodyTrustedDiv {
  display: flex;
  flex-direction: row;
}
.HomeBodyTrustedContent {
  margin: 10px 20px 10px 0px;
}

/* Section 2 */

.HomeTopScreen2 {
  background: rgba(84, 71, 167, 1);
  min-height: 550px;
  width: 100%;
}
.HomeServiceSolveTop {
  display: grid;
  grid-template-columns: 50% 50%;
  color: #fff;
  padding: 50px 80px 50px;
}
.HomeServiceSolveTopText {
  font-size: 40px;
  font-weight: 500;
  color: #fff;
}
.UXUIDiv {
  border: 2px solid rgba(255, 255, 255, 0.28);
  height: 320px;
  margin: 10px 30px 100px;
  padding: 30px;
  border-radius: 30px;
  text-align: center;
  color: #fff;
}
.UXUIBody {
  color: #fff;
  font-size: 16px;
  width: 300px;
}
.UXUIBackImage {
  /* position: absolute; */
}
.scrollcontant {
  display: flex;
  overflow: auto;
}

/* Section three */
.HomeTopScreen3 {
  background-image: linear-gradient(
    to bottom,
    rgba(252, 251, 255, 1),
    rgba(241, 230, 255, 1)
  );
  min-height: 600px;
  width: 100%;
  margin-top: -50px;
  padding-top: 50px;
}
.Homehr1 {
  height: 5px;
  width: 50px;
  background: rgba(116, 63, 169, 1);
  margin: auto;
}
.OurDomain {
  text-align: center;
  margin: 10px 0px 10px;
}
.data1Div {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  /* float: left; */
  margin: 50px 150px 0px;
  padding-bottom: 50px;
}
.data1Icon {
  margin: auto;
  text-align: center;
  background: #fff;
  height: 180px;
  width: 200px;
  padding-top: 30px;
  margin-bottom: 30px;
  border-top-right-radius: 50px;
  border-bottom-left-radius: 50px;
}
.data1IconText {
  margin: 20px 30px 20px;
}
/* Section Four */
.HomeTopScreen4 {
  min-height: 200px;
  width: 100%;
  color: #fff;
}
.Hometop4Map {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  padding: 30px;
  text-align: center;
  justify-content: center;
}
/* Section Five */

.HomeTopScreen5 {
  background-image: linear-gradient(
    to bottom,
    rgba(252, 251, 255, 1),
    rgba(241, 230, 255, 1)
  );
  min-height: 550px;
  width: 100%;
  padding-top: 50px;
  padding-bottom: 300px;
}
.whycustomerContant {
  padding-top: 20px;
  width: 50%;
  margin: auto;
}
.whycustomerContant1 {
  margin: 0px 20px 0px;
}

/* HorizontalScroller.css */

.employee-scroller {
  position: absolute;
  width: 100%; /* Adjust width as needed */
  padding: 50px 150px 50px;
  overflow: hidden;
}
.scroll-container {
  display: flex;
  transition: transform 0.5s ease;
  /* padding: 0px 50px 0px; */
}
.employee {
  flex: 0 0 calc(100% / 5); /* Display 5 employees at a time */
  padding: 10px;
  text-align: center;
  transition: filter 0.3s ease;
}
.employee.center {
  filter: brightness(1); /* Adjust the brightness for the centered employee */
  transform: scale(1.2); /* Increase size for centered employee */
  transition: transform 0.5s ease-in-out;
}

/* Remaining CSS styles remain the same */

.employee img {
  width: 100px; /* Adjust image size as needed */
  height: 100px;
  border-radius: 50%;
  margin-bottom: 10px;
}
.scroll-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  /* background-color: rgba(0, 0, 0, 0.5); */
  color: rgba(0, 0, 0, 0.5);
  border: none;
  padding: 10px;
  cursor: pointer;
}
.scroll-button.left:hover,
.scroll-button.right:hover {
  background-color: rgba(0, 0, 0, 0.4);
}
.scroll-button.left {
  left: 0;
  margin-left: 50px;
}
.scroll-button.right {
  right: 0;
  margin-right: 50px;
}

/* Section Six */

.HomeTopScreen6 {
  height: 600px;
  width: 100%;
  color: #fff;
  background-repeat: no-repeat;
  background-size: 100%;
}
.Somedistinguishing {
  width: 800px;
  margin: auto;
  text-align: center;
  padding: 50px 0px 20px;
}
.Somedistinguishing1 {
  text-align: center;
}
.ProvideExpertise {
  background-image: linear-gradient(
    rgba(217, 217, 217, 0.25),
    rgba(217, 217, 217, 0.25)
  );
  width: 80%;
  height: 350px;
  margin: auto;
  display: flex;
  justify-content: space-evenly;
  border-radius: 20px;
}
.ProvideExpertisemap {
  height: 250px;
  width: 280px;
  background: #fff;
  float: left;
  margin: 50px 0px 40px 0px;
  color: #000;
  /* padding: 40px ; */
  border-radius: 20px;
}
.ProvideExpertiseTop {
  height: 30px;
  width: 50%;
  background: #fff;
  margin: auto;
  border: 1px solid rgba(237, 237, 237, 1);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
}
.ProvideExpertiseImage{
  float: left;
  margin-right: 15px;
}
.ProvideExpertiseContantDiv {
  width: 70%;
  margin: auto;
  margin-top: 20px;
}
.ProvideExpertiseBody {
  color: rgba(103, 106, 109, 1);
  font-size: 15px;
}
.ProvideExpertiseTopdesign1 {
  height: 3px;
  width: 50%;
  border-radius: 20px;
  background-color: rgba(147, 107, 255, 1);
  /* float: left;
  margin-right: 50px; */
}
.ProvideExpertiseTopdesign2 {
  height: 5px;
  width: 5px;
  border-radius: 20px;
  background-color: rgba(147, 107, 255, 1);
  /* float: left;
  right: 0px; */
}
/* Section Seven */
.HomeTopScreen7 {
  min-height: 250px;
  background: #fff;
  padding-top: 25px;
}
.HireDiv {
  height: 200px;
  width: 80%;
  margin: auto;
  background: rgba(230, 230, 243, 1);
  border-radius: 20px;
  padding: 25px 80px 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.HireDivh2 {
  width: 50%;
}

/* Section Eight */
.HomeTopScreen8 {
  min-height: 550px;
  width: 100%;
  background: rgba(84, 71, 167, 1);
  color: #fff;
  padding-top: 50px;
  text-align: center;
}
.expertMainDiv {
  margin: 50px 200px 0px;
  display: flex;
  justify-content: space-evenly;
}
.expertDiv {
  height: 280px;
  width: 200px;
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.29),
    rgba(255, 255, 255, 0.39)
  );
  border-radius: 20px;
}
.expertImage {
  border-radius: 20px;
  margin: 20px 0px 10px;
}
.expertName {
  font-size: 16px;
  font-weight: bold;
}
.expertRole {
  font-size: 16px;
  font-weight: 300;
}
.expertShareDiv {
  display: flex;
  justify-content: space-evenly;
  margin: -15px 20px 0px;
}
.expertShare {
  border: 1px solid #fff;
  width: 30px;
  height: 30px;
  border-radius: 50px;
  margin: 8px;
}

/* Section Nine */
.HomeTopScreen9 {
  background-image: linear-gradient(
    to bottom,
    rgba(252, 251, 255, 1),
    rgba(241, 230, 255, 1)
  );
  min-height: 550px;
  width: 100%;
  padding: 50px 100px 50px;
  display: grid;
  grid-template-columns: 50% 50%;
}
.HomeTopScreen9Left {
  display: flex;
  justify-content: space-evenly;
  margin: 10px 50px 10px;
}
.HomeTopScreen9Left1{
  display: grid;
  justify-content: space-evenly;
}
.HomeTopScreen9Image {
  margin-bottom: 20px;
}
.HomeTopScreen9Right {
  padding: 20px 0px 50px;
  color: #000;
}
.RecentProjects {
  background: #fff;
  border: 1px solid rgba(140, 77, 223, 1);
  border-radius: 50px;
  padding: 10px 20px 10px;
}
.imageIconImageDiv {
  display: flex;
  justify-content: center;
}

/* Media query for screen sizes*/
@media screen and (min-width: 1px) and (max-width: 600px) {
  /* Section 1 */
  .HomeTopScreen {
    height: 430px;
  }
  .HomeTopCircle,
  .HomeBottomCircle {
    height: 40px;
    width: 40px;
  }
  .HomeBody {
    grid-template-columns: 50% 50%;
    margin: 0px 50px 0px;
  }
  .HomeBodyText,
  .HomeBodyText1 {
    padding: 0px;
    width: 100%;
    font-size: 20px;
    margin: 0px;
  }
  .HomeBodyText2 {
    font-size: 12px;
  }
  .imageIconImage {
    height: 150px;
    width: 200px;
  }
  .HomeBodyButton,
  .HomeBodyButton1 {
    border-radius: 30px;
    padding: 5px 10px 8px;
    margin-right: 5px;
  }
  .HomeBodyButtonText,
  .HomeBodyButtonText1 {
    font-size: 12px;
  }
  .HomeBodyTrusted {
    margin: 10px 0px 10px;
  }
  .HomeBodyTrustedContent {
    margin: 5px 10px 5px 0px;
  }
  /* Section 2 */
  .HomeServiceSolveTop {
    padding: 50px 30px 50px;
  }
  .HomeServiceSolveTopText {
    font-size: 25px;
    padding: 0px 30px 0px 0px;
  }
  .HomeServiceSolveTopText1 {
    font-size: 14px;
  }
  .UXUIDiv {
    height: 280px;
    margin: 10px 20px 100px;
    padding: 10px;
    border-radius: 30px;
  }
  .UXUIBody {
    color: #fff;
    font-size: 14px;
    width: 200px;
  }
  .UXUIh2 {
    font-size: 20px;
  }

  /* Section 3 */

  .OurDomainText {
    font-size: 25px;
  }
  .OurDomainText1 {
    font-size: 14px;
  }
  .data1Div {
    display: grid;
    grid-template-columns: 50% 50%;
    /* float: left; */
    margin: 50px 20px 0px;
    padding-bottom: 50px;
  }
  .data1Icon {
    margin: auto;
    text-align: center;
    height: 160px;
    width: 150px;
    padding-top: 30px;
    margin-bottom: 30px;
  }
  .data1IconImage {
    height: 45px;
  }

  /* Section 4 */

  .Hometop4Map {
    padding: 30px;
    text-align: center;
  }
  .Hometop4Maph1 {
    font-size: 25px;
    margin: 10px 0px 5px;
  }
  .Hometop4MapImage {
    height: 40px;
  }
  .Hometop4MapText {
    font-size: 14px;
  }

  /* Section 5 */

  .HomeTopScreen5 {
    padding-bottom: 0px;
    min-height: 50px;
  }
  .whycustomerContant {
    width: 75%;
    margin: auto;
  }
  .whycustomerContant1 {
    margin: 0px 10px 0px;
  }
  /* HorizontalScroller.css */
  .employee-scroller {
    width: 100%; /* Adjust width as needed */
    padding: 50px 30px 0px 30px;
  }
  /* Remaining CSS styles remain the same */
  .employee img {
    width: 50px; /* Adjust image size as needed */
    height: 50px;
  }
  .employeeh6 {
    font-size: 14px;
  }
  .employeeText {
    font-size: 12px;
  }
  .scroll-button.left {
    margin-left: 10px;
  }
  .scroll-button.right {
    margin-right: 10px;
  }

  /* Section 6 */
  .HomeTopScreen6 {
    height: 1100px;
    background-size: 500%;
  }
  .Somedistinguishing {
    font-size: 20px;
    width: 80%;
    margin: auto;
  }
  .Somedistinguishing1 {
    font-size: 16px;
  }
  .ProvideExpertise {
    padding: 30px 0px 30px;
    width: 80%;
    height: 900px;
    display: grid;
    justify-content: center;
    align-items: center;
  }
  .ProvideExpertisemap {
    height: 250px;
    width: 280px;
    margin: 0px 0px 0px 0px;
    float: none;
  }
  /* Section 7 */
  .HireDiv {
    width: 80%;
    padding: 20px 30px 20px;
  }
  .HireDivh2 {
    font-size: 25px;
  }
  .HireDivImage {
    height: 100px;
  }
  /* Section 8 */
  .expertMainDiv {
    margin: 40px 0px 0px;
    display: flex;
    justify-content: space-evenly;
  }
  .expertDiv {
    height: 280px;
    width: 150px;
  }
  .expertImage {
    height:90px ;
    width:130px ;
  }
  .expertName {
    font-size: 14px;
  }
  .expertRole {
    font-size: 12px;
  }
  .expertShareDiv {
    margin: 0px 8px 0px;
  }
  /* Section 9 */
  .HomeTopScreen9 {
    padding: 50px 30px 0px 30px;
    grid-template-columns: 100%;
    justify-content: center;
  }
  .HomeTopScreen9Left {
    margin:0px;
  }
  .HomeTopScreen9Left1{
    display: grid;
    justify-content: center;
  }
  .HomeTopScreen9Right {
    padding: 30px 0px 30px;
  }
  .RecentProjects {
    padding: 10px 20px 10px;
  }
  .HomeTopScreen9Righth5{
    font-size: 20px;
  }
  .HomeTopScreen9Righth1{
    font-size: 25px;
  }
  .HomeTopScreen9Rightpara{
    font-size: 15px;
  }
}

@media screen and (min-width: 601px) and (max-width: 800px) {
  /* Section 1 */
  .HomeTopScreen {
    height: 520px;
  }
  .HomeTopCircle,
  .HomeBottomCircle {
    height: 50px;
    width: 50px;
  }
  .HomeBody {
    grid-template-columns: 50% 50%;
    margin: 0px 50px 0px;
  }
  .HomeBodyText,
  .HomeBodyText1 {
    padding: 0px;
    width: 100%;
    font-size: 28px;
    margin: 0px;
  }
  .HomeBodyText2 {
    font-size: 18px;
  }
  .imageIconImage {
    height: 220px;
    width: 280px;
  }
  .HomeBodyButton,
  .HomeBodyButton1 {
    border-radius: 30px;
    padding: 8px 18px 10px;
    margin-right: 5px;
  }
  .HomeBodyButtonText,
  .HomeBodyButtonText1 {
    font-size: 14px;
  }
  .HomeBodyTrusted {
    margin: 10px 0px 10px;
  }
  .HomeBodyTrustedContent {
    margin: 5px 10px 5px 0px;
  }
  /* Section 2 */
  .HomeServiceSolveTop {
    padding: 50px 50px 50px;
  }
  .HomeServiceSolveTopText {
    font-size: 28px;
    padding: 0px 30px 0px 0px;
  }
  .HomeServiceSolveTopText1 {
    font-size: 16px;
  }
  .UXUIDiv {
    height: 280px;
    margin: 10px 20px 100px;
    padding: 10px;
    border-radius: 30px;
  }
  .UXUIBody {
    color: #fff;
    font-size: 14px;
    width: 200px;
  }
  .UXUIh2 {
    font-size: 20px;
  }

  /* Section 3 */

  .OurDomainText {
    font-size: 25px;
  }
  .OurDomainText1 {
    font-size: 14px;
  }
  .data1Div {
    display: grid;
    grid-template-columns: 33.33% 33.33% 33.33%;
    /* float: left; */
    margin: 70px 20px 0px;
    padding-bottom: 50px;
  }
  .data1Icon {
    margin: auto;
    text-align: center;
    height: 180px;
    width: 170px;
    padding-top: 30px;
    margin-bottom: 30px;
  }
  .data1IconImage {
    height: 45px;
  }

  /* Section 4 */

  .Hometop4Map {
    padding: 30px;
    text-align: center;
  }
  .Hometop4Maph1 {
    font-size: 28px;
    margin: 10px 0px 5px;
  }
  .Hometop4MapImage {
    height: 40px;
  }
  .Hometop4MapText {
    font-size: 16px;
  }

  /* Section 5 */

  .HomeTopScreen5 {
    padding-bottom: 0px;
    min-height: 500px;
  }
  .whycustomerContant {
    width: 75%;
    margin: auto;
  }
  .whycustomerContant1 {
    margin: 0px 10px 0px;
  }
  /* HorizontalScroller.css */
  .employee-scroller {
    width: 100%; /* Adjust width as needed */
    padding: 50px 30px 0px 30px;
  }
  /* Remaining CSS styles remain the same */
  .employee img {
    width: 50px; /* Adjust image size as needed */
    height: 50px;
  }
  .employeeh6 {
    font-size: 16px;
  }
  .employeeText {
    font-size: 14px;
  }
  .scroll-button.left {
    margin-left: 10px;
  }
  .scroll-button.right {
    margin-right: 10px;
  }

  /* Section 6 */
  .HomeTopScreen6 {
    height: 750px;
    background-size: 200%;
  }
  .Somedistinguishing {
    font-size: 25px;
    width: 80%;
    margin: auto;
  }
  .Somedistinguishing1 {
    font-size: 18px;
  }
  .ProvideExpertise {
    padding: 30px 0px 30px;
    width: 90%;
    height: 500px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
  }
  .ProvideExpertisemap {
    height: 380px;
    width: 160px;
    margin: 0px;
    float: left;
  }
  .ProvideExpertiseImage{
    height: 40px;
    float: none;
  }
  .ProvideExpertiseh5{
    font-size: 18px;
    font-weight: bold;
    margin: 10px 0px 10px;
  }
  .ProvideExpertiseBody{
    font-size: 14px;
  }
  /* Section 7 */
  .HireDiv {
    width: 80%;
    padding: 20px 50px 20px;
  }
  .HireDivh2 {
    font-size: 30px;
  }
  .HireDivImage {
    height: 140px;
  }
  /* Section 8 */
  .expertMainDiv {
    margin: 40px 0px 0px;
  }
  .expertDiv {
    width: 180px;
  }
  .expertShareDiv {
    margin: 0px 8px 0px;
  }
  /* Section 9 */
  .HomeTopScreen9 {
    min-height: 400px;
    padding: 50px 30px 0px 30px;
    justify-content: center;
  }
  .HomeTopScreen9Left {
    margin:0px;
  }
  .HomeTopScreen9Left1{
    height: 300px;
    display: grid;
    justify-content: space-evenly;
  }
  .HomeTopScreen9Right {
    padding: 0px 0px 0px;
  }
  .HomeTopScreen9Image, .HomeTopScreen9Image1{
    height: 120px;
  }
  .RecentProjects {
    padding: 10px 20px 10px;
  }
  .HomeTopScreen9Righth5{
    font-size: 16px;
  }
  .HomeTopScreen9Righth1{
    font-size: 20px;
  }
  .HomeTopScreen9Rightpara{
    font-size: 12px;
  }
}

@media screen and (min-width: 801px) and (max-width: 1000px) {
  /* Section 1 */
  .HomeTopScreen {
    height: 580px;
  }
  .HomeTopCircle,
  .HomeBottomCircle {
    height: 60px;
    width: 60px;
  }
  .HomeBody {
    grid-template-columns: 50% 50%;
    margin: 0px 50px 0px;
  }
  .HomeBodyText,
  .HomeBodyText1 {
    padding: 0px;
    width: 100%;
    font-size: 40px;
    margin: 0px;
  }
  .HomeBodyText2 {
    font-size: 20px;
  }
  .imageIconImage {
    height: 300px;
    width: 380px;
  }
  .HomeBodyButton,
  .HomeBodyButton1 {
    border-radius: 30px;
    padding: 10px 30px 12px;
    margin-right: 10px;
  }
  .HomeBodyButtonText,
  .HomeBodyButtonText1 {
    font-size: 16px;
  }
  .HomeBodyTrusted {
    font-size: 20px;
    margin: 12px 0px 12px;
  }
  .HomeBodyTrustedContent {
    margin: 5px 10px 5px 0px;
  }
  /* Section 2 */
  .HomeServiceSolveTop {
    padding: 50px 50px 50px;
  }
  .HomeServiceSolveTopText {
    font-size: 32px;
    padding: 0px 30px 0px 0px;
  }
  .HomeServiceSolveTopText1 {
    font-size: 18px;
  }
  .UXUIDiv {
    height: 350px;
    margin: 20px 20px 100px;
    padding: 40px 30px 40px;
    border-radius: 30px;
  }
  .UXUIBody {
    color: #fff;
    font-size: 16px;
    width: 250px;
  }
  .UXUIh2 {
    font-size: 25px;
  }

  /* Section 3 */

  .OurDomainText {
    font-size: 35px;
  }
  .OurDomainText1 {
    font-size: 22px;
  }
  .data1Div {
    /* float: left; */
    margin: 70px 50px 0px;
    padding-bottom: 50px;
  }
  .data1Icon {
    height: 200px;
    width: 160px;
    padding-top: 50px;
    margin-bottom: 30px;
  }
  .data1IconImage {
    height: 55px;
  }

  /* Section 4 */

  .Hometop4Map {
    padding: 30px;
    text-align: center;
  }
  .Hometop4Maph1 {
    font-size: 30px;
    margin: 10px 0px 5px;
  }
  .Hometop4MapImage {
    height: 45px;
  }
  .Hometop4MapText {
    font-size: 20px;
  }

  /* Section 5 */

  .HomeTopScreen5 {
    padding-bottom: 0px;
    min-height: 550px;
  }
  .whycustomerContant {
    width: 60%;
    margin: auto;
  }
  .whycustomerContant1 {
    margin: 0px 20px 0px;
  }
  /* HorizontalScroller.css */
  .employee-scroller {
    width: 100%; /* Adjust width as needed */
    padding: 50px 60px 50px 60px;
    margin: auto;
  }
  /* Remaining CSS styles remain the same */
  .employee img {
    width: 80px; /* Adjust image size as needed */
    height: 80px;
  }
  .employeeh6 {
    font-size: 20px;
  }
  .employeeText {
    font-size: 15px;
  }
  .scroll-button.left {
    margin-left: 10px;
  }
  .scroll-button.right {
    margin-right: 10px;
  }
  /* Section 6 */
  .HomeTopScreen6 {
    height: 680px;
    background-size: 150%;
  }
  .Somedistinguishing {
    font-size: 30px;
    width: 85%;
    margin: auto;
  }
  .Somedistinguishing1 {
    font-size: 20px;
  }
  .ProvideExpertise {
    width: 90%;
    height: 380px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .ProvideExpertisemap {
    height: 270px;
    width: 220px;
    margin: 0px;
  }
  .ProvideExpertiseImage{
    height: 40px;
    float: left;
  }
  .ProvideExpertiseh5{
    font-size: 16px;
    font-weight: bold;
    margin: 10px 0px 10px;
  }
  .ProvideExpertiseBody{
    font-size: 14px;
  }
  /* Section 7 */
  .HireDiv {
    width: 80%;
    padding: 20px 50px 20px;
  }
  .HireDivh2 {
    font-size: 30px;
  }
  .HireDivImage {
    height: 140px;
  }
  /* Section 8 */
  .expertMainDiv {
    margin: 40px 0px 0px;
    display: flex;
    justify-content: space-evenly;
  }
  .expertDiv {
    height: 280px;
    width: 200px;
  }
  .expertImage {
    height:100px ;
  }
  
  /* Section 9 */
  .HomeTopScreen9 {
    min-height: 450px;
    padding: 50px 30px 0px 30px;
    justify-content: center;
  }
  .HomeTopScreen9Left {
    margin:0px;
  }
  .HomeTopScreen9Left1{
    height: 300px;
    display: grid;
    justify-content: space-evenly;
  }
  .HomeTopScreen9Right {
    padding: 0px 0px 0px;
  }
  .HomeTopScreen9Image, .HomeTopScreen9Image1{
    height: 150px;
  }
  .RecentProjects {
    padding: 10px 20px 10px;
  }
  .HomeTopScreen9Righth5{
    font-size: 18px;
  }
  .HomeTopScreen9Righth1{
    font-size: 25px;
  }
  .HomeTopScreen9Rightpara{
    font-size: 15px;
  }
}

@media screen and (min-width: 1001px) and (max-width: 1200px) {
  /* Section 1 */
  .HomeTopScreen {
    height: 640px;
  }
  .HomeBody {
    grid-template-columns: 50% 50%;
    margin: 0px 50px 0px;
  }
  .HomeBodyText,
  .HomeBodyText1 {
    padding: 0px;
    width: 100%;
    font-size: 50px;
    margin: 0px;
  }
  .HomeBodyButton,
  .HomeBodyButton1 {
    border-radius: 30px;
    margin-right: 20px;
  }
  .HomeBodyTrusted {
    font-size: 20px;
    margin: 12px 0px 12px;
  }
  .HomeBodyTrustedContent {
    margin: 5px 10px 5px 0px;
  }
  /* Section 2 */
  .HomeServiceSolveTop {
    padding: 50px 50px 50px;
  }
  .HomeServiceSolveTopText {
    font-size: 35px;
    padding: 0px 30px 0px 0px;
  }
  .HomeServiceSolveTopText1 {
    font-size: 18px;
  }
  .UXUIDiv {
    height: 350px;
    margin: 20px 20px 100px;
    padding: 40px 30px 40px;
    border-radius: 30px;
  }
  .UXUIBody {
    color: #fff;
    font-size: 16px;
    width: 250px;
  }
  .UXUIh2 {
    font-size: 25px;
  }

  /* Section 3 */

  .OurDomainText {
    font-size: 35px;
  }
  .OurDomainText1 {
    font-size: 22px;
  }
  .data1Div {
    /* float: left; */
    margin: 70px 100px 0px;
    padding-bottom: 50px;
  }
  .data1Icon {
    height: 200px;
    width: 180px;
    padding-top: 50px;
  }
  .data1IconImage {
    height: 55px;
  }

  /* Section 4 */


  /* Section 5 */

 
  /* Section 6 */
  .HomeTopScreen6 {
    height: 680px;
    background-size: 100%;
  }
  .Somedistinguishing {
    font-size: 30px;
    width: 85%;
    margin: auto;
  }
  .Somedistinguishing1 {
    font-size: 20px;
  }
  .ProvideExpertise {
    width: 90%;
    height: 350px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .ProvideExpertisemap {
    height: 250px;
    width: 250px;
    margin: 0px;
  }
  .ProvideExpertiseh5{
    font-size: 16px;
    font-weight: bold;
    margin: 10px 0px 10px;
  }
  .ProvideExpertiseBody{
    font-size: 14px;
  }
  /* Section 7 */
  
  /* Section 8 */
  .expertMainDiv {
    margin: 40px 100px 0px;
    display: flex;
    justify-content: space-evenly;
  }
  .expertDiv {
    height: 280px;
    width: 200px;
  }
  .expertImage {
    height:100px ;
  }
  
  /* Section 9 */
  .HomeTopScreen9 {
    min-height: 450px;
    padding: 50px 30px 50px 30px;
    justify-content: center;
  }
  .HomeTopScreen9Left {
    margin:0px;
  }
  .HomeTopScreen9Left1{
    height: 400px;
    display: grid;
    justify-content: space-evenly;
  }
  .HomeTopScreen9Image, .HomeTopScreen9Image1{
    height: 180px;
  }
  .RecentProjects {
    padding: 10px 20px 10px;
  }
}

@media screen and (min-width: 1201px) and (max-width: 1400px) {
  /* Section 1 */
  
  /* Section 2 */

  /* Section 3 */

  /* Section 4 */

  /* Section 5 */
 
  /* Section 6 */

  .HomeTopScreen6 {
    height: 630px;
  }
  .ProvideExpertise {
    height: 350px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .ProvideExpertisemap {
    width: 250px;
    margin: 50px 0px 40px 0px;
  }

  /* Section 7 */
  
  /* Section 8 */
  
  /* Section 9 */
  
  .HomeTopScreen9 {
    padding: 50px 30px 50px 30px;
  }
  .HomeTopScreen9Image, .HomeTopScreen9Image1{
    height: 190px;
  }
}