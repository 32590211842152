/* Section 1 */

.MobileAppTopScreen {
  min-height: 500px;
  background-repeat: no-repeat;
  background-size: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 60% 40%;
  padding: 120px 80px 50px;
  color: #fff;
  align-items: center;
  justify-content: space-between;
}
.MobileApph5 {
  margin: 20px 0px 20px;
}
.MobileAppTopScreenDiv2 {
  text-align: center;
}
.Letstalkbtn {
  background: #fff;
  padding: 5px 20px 5px;
  color: #000;
  border: 0px;
  border-radius: 30px;
  margin-top: 20px;
}
/* Section 2 */
.MobileAppTopScreen2 {
  background: #fff;
  min-height: 450px;
  width: 100%;
  padding: 10px;
}
.CircleDot {
  display: flex;
  justify-content: space-between;
}
.CircleDiv {
  height: 80px;
  width: 80px;
  background-image: linear-gradient(
    to right,
    rgba(154, 242, 242, 1),
    rgba(253, 240, 255, 1)
  );
  border-radius: 40px;
}
.MobileAppTopScreen2Contact {
  padding: 0px 70px 50px;
  text-align: center;
}
.Line5 {
  height: 3px;
  width: 5%;
  background: rgba(116, 63, 169, 1);
  margin: auto;
  margin-top: -50px;
}
.Servicesh1 {
  color: rgba(116, 63, 169, 1);
  margin-bottom: 20px;
}
.ServicesMainDiv {
  display: grid;
  grid-template-columns: 50% 50%;
}
.ServicesDiv {
  width: 90%;
  margin: 20px 20px 10px;
  border-top: 4px solid rgba(116, 63, 169, 1);
  box-shadow: 0px 0px 10px rgba(151, 71, 255, 0.11);
  padding: 20px 20px 10px;
  text-align: left;
  display: flex;
  border-radius: 10px;
}
.NumberDiv {
  height: 40px;
  padding: 15px 15px 15px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
}
/* Section 3 */
.MobileAppTopScreen3 {
  background-image: linear-gradient(
    to bottom,
    rgba(252, 251, 255, 1),
    rgba(241, 230, 255, 1)
  );
  min-height: 550px;
  width: 100%;
}
.Line6 {
  height: 3px;
  width: 5%;
  background: rgba(116, 63, 169, 1);
  margin: auto;
  margin-top: -20px;
}
.MobileAppDevelopmentMaindiv {
  display: grid;
  grid-template-columns: 50% 50%;
  margin: 50px 0px 50px;
}
.whychooseDiv {
  display: flex;
  text-align: left;
}
.whychooseInnerDiv1 {
  margin-right: 20px;
}
.whychooseInnerDiv2 {
}
.whychooseText {
  color: rgba(103, 106, 109, 1);
}
.MobileAppDevelopmentImageDiv {
  display: flex;
  justify-content: center;
  align-items: center;
}
/* Section 4 */
.MobileAppTopScreen4 {
  background: #fff;
  min-height: 450px;
  width: 100%;
  padding: 50px 80px 50px;
}
.iOSUnderLine {
  text-decoration: underline;
}
.WebDesignDiv12 {
  background-image: linear-gradient(
    to bottom,
    rgba(252, 251, 255, 1),
    rgba(241, 230, 255, 1)
  );
  height: 310px;
  width: 100%;
  margin: 50px 0px 50px;
  border-radius: 30px;
  color: #000;
  text-align: center;
  padding: 30px 80px 50px;
}
.WebDesignDivh1 {
  display: flex;
  justify-content: center;
  align-self: center;
}
.ContactUsBtn {
  padding: 10px 30px 10px;
  background-color: rgba(116, 63, 169, 1);
  border-radius: 30px;
  border: 0px;
  color: #fff;
}

/* Media query for screen sizes */
@media screen and (min-width: 1px) and (max-width: 600px) {
  /* Section 1 */
  .MobileAppTopScreen {
    min-height: 550px;
    background-size: 250%;
    padding: 120px 20px 50px;
  }
  .MobileAppTopImage {
    height: 250px;
    width: 95%;
  }
  .MobileApph1 {
    font-size: 20px;
  }
  .MobileApph5 {
    font-size: 16px;
  }
  .MobileAppPara {
    font-size: 12px;
  }
  /* Section 2 */
  .CircleDiv1 {
    height: 50px;
    width: 50px;
  }
  .Dot11 {
    height: 50px;
  }
  .MobileAppTopScreen2Contact {
    padding: 0px 20px 50px;
  }
  .Line5 {
    width: 10%;
    margin-top: -10px;
  }
  .Servicesh1Text {
    font-size: 22px;
  }
  .ServicesMainDiv {
    grid-template-columns: 100%;
    justify-content: center;
    align-items: center;
  }
  .ServicesDiv {
    width: 90%;
    padding: 20px 20px 10px;
  }
  .Servicestext,
  .whychooseh6 {
    font-size: 16px;
  }
  .ServicesPara,
  .whychooseText {
    font-size: 14px;
  }
  /* Section 3 */
  .MobileAppDevelopmentImage {
    height: 280px;
    width: 220px;
  }

  /* Section 4 */
  .MobileAppTopScreen4 {
    min-height: 300px;
    padding: 50px 40px 0px;
  }
  .WebDesignDiv12 {
    height: 430px;
    padding: 30px 30px 50px;
  }
}

@media screen and (min-width: 601px) and (max-width: 800px) {
  /* Section 1 */
  .MobileAppTopScreen {
    min-height: 600px;
    background-size: 250%;
    padding: 120px 30px 50px;
  }
  .MobileAppTopImage {
    height: 320px;
    width: 95%;
  }
  .MobileApph1 {
    font-size: 25px;
  }
  .MobileApph5 {
    font-size: 20px;
  }
  /* Section 2 */
  .CircleDiv1 {
    height: 60px;
    width: 60px;
  }
  .Dot11 {
    height: 50px;
  }
  .MobileAppTopScreen2Contact {
    padding: 0px 30px 50px;
  }
  .Line5 {
    width: 10%;
    margin-top: -20px;
  }
  .Servicesh1Text {
    font-size: 25px;
  }
  .ServicesMainDiv {
    grid-template-columns: 100%;
    justify-content: center;
    align-items: center;
  }
  .ServicesDiv {
    width: 90%;
    padding: 20px 20px 10px;
  }
  /* Section 3 */
  .MobileAppDevelopmentImage {
    height: 380px;
    width: 280px;
  }
  /* Section 4 */
  .MobileAppTopScreen4 {
    min-height: 280px;
    padding: 50px 40px 0px;
  }
  .WebDesignDiv12 {
    height: 380px;
    padding: 30px 40px 50px;
  }
  .ContactUsBtn {
    width: 40%;
    margin: auto;
  }
}

@media screen and (min-width: 801px) and (max-width: 1000px) {
  /* Section 1 */
  .MobileAppTopScreen {
    min-height: 500px;
    background-size: 150%;
    padding: 120px 40px 50px;
  }
  .MobileAppTopImage {
    height: 320px;
    width: 95%;
  }
  .MobileApph1 {
    font-size: 30px;
  }
  /* Section 2 */
  .CircleDiv1 {
    height: 70px;
    width: 70px;
  }
  .Dot11 {
    height: 60px;
  }
  .MobileAppTopScreen2Contact {
    padding: 0px 40px 50px;
  }
  .Line5 {
    width: 10%;
    margin-top: -20px;
  }
  /* Section 3 */
  .MobileAppDevelopmentImage {
    height: 300px;
  }
  /* Section 4 */
  .MobileAppTopScreen4 {
    min-height: 280px;
    padding: 50px 40px 0px;
  }
  .WebDesignDiv12 {
    height: 320px;
    padding: 30px 40px 50px;
  }
}

@media screen and (min-width: 1001px) and (max-width: 1200px) {
  /* Section 1 */
  .MobileAppTopScreen {
    min-height: 500px;
    background-size: 120%;
    padding: 120px 40px 50px;
  }
  /* Section 2 */
  /* Section 3 */
  /* Section 4 */
}

@media screen and (min-width: 1201px) and (max-width: 1400px) {
  /* Section 1 */
  /* Section 2 */
  /* Section 3 */
  /* Section 4 */
}
