/* Section 1 */

.UXUITopScreen {
  min-height: 600px;
  background-repeat: no-repeat;
  background-size: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 60% 40%;
  padding: 110px 80px 50px;
  color: #fff;
  align-items: center;
  justify-content: space-between;
}
.UXUIh5 {
  margin: 20px 0px 20px;
}
.UXUITopScreenDiv2 {
  text-align: center;
}
.Letstalkbtn {
  background: #fff;
  padding: 5px 20px 5px;
  color: #000;
  border: 0px;
  border-radius: 30px;
  margin-top: 20px;
}
/* Section 2 */
.UXUITopScreen2 {
  background: #fff;
  min-height: 350px;
  width: 100%;
  display: grid;
  grid-template-columns: 40% 60%;
  padding: 50px 80px 0px;
  align-items: center;
  justify-content: space-between;
}
.Talktousbtn {
  background: rgba(140, 77, 223, 1);
  color: #fff;
  padding: 5px 20px 5px;
  border: 0px;
  border-radius: 30px;
}
.UIandUXdesign {
  margin: 20px 0px 20px;
}
/* Section 3 */
.UXUITopScreen3 {
  background: #fff;
  min-height: 400px;
  width: 100%;
}
.CircleDot {
  display: flex;
  justify-content: space-between;
}
.CircleDiv {
  height: 80px;
  width: 80px;
  background-image: linear-gradient(
    to bottom,
    rgba(252, 251, 255, 1),
    rgba(241, 230, 255, 1)
  );
  border-radius: 40px;
}
.UXUITopScreen3Contact {
  padding: 0px 80px 0px;
}
.OURUIDESIGNDiv {
  display: flex;
}
.OURUIDESIGNDiv {
  display: flex;
  margin-bottom: 20px;
}
.SERVICES1 {
  color: rgba(140, 77, 223, 1);
  margin-left: 10px;
}
.UIservicesincludeImageDiv {
  height: 50px;
  width: 200px;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 30px;
}
.UIservicesincludeImage {
  height: auto;
  width: 50px;
  object-fit: contain;
  padding: 10px;
  margin-right: 30px;
  border-radius: 10px;
}
.UIservicesincludeMainDiv {
  display: grid;
  grid-template-columns: 50% 50%;
  margin: 30px 0px 0px;
}
.UIservicesincludeDiv {
  display: flex;
  width: 85%;
  margin-bottom: 20px;
}
.dilDiv {
  height: 30px;
  width: 30px;
  background: #fff;
  box-shadow: 0px 0px 2px #4c4b4b;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}
.KeyaspectsofUIdesign {
  display: flex;
  margin: 10px 0px 10px;
}
.KeyaspectsofUIdesignText {
  margin: 0px 10px 0px 15px;
  display: flex;
}
.KeyaspectsofUIdesignDiv {
  margin: 30px 0px 20px;
}
.KeyaspectsofUIdesignh5 {
  margin-right: 10px;
}
.CircleDiv2 {
  height: 80px;
  width: 80px;
  background-image: linear-gradient(
    to right,
    rgba(154, 242, 242, 1),
    rgba(253, 240, 255, 1)
  );
  border-radius: 40px;
}
.CollaborationImage {
  height: 550px;
  width: 80%;
  margin: auto;
  background-repeat: no-repeat;
  background-size: 100%;
  color: #fff;
  padding: 50px 40px 50px;
  border-radius: 30px;
}
.CollaborationText {
  margin-bottom: 25px;
}
.WebDesignDiv {
  background-image: linear-gradient(
    to bottom,
    rgba(252, 251, 255, 1),
    rgba(241, 230, 255, 1)
  );
  height: 320px;
  width: 100%;
  margin: 30px 0px 80px;
  border-radius: 30px;
  color: #000;
  text-align: center;
  padding: 50px 40px 50px;
}
.WebDesignDivh1 {
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  align-self: center;
}

/* Media query for screen sizes */
@media screen and (min-width: 1px) and (max-width: 600px) {
  /* Section 1 */
  .UXUITopScreen {
    min-height: 600px;
    background-size: 250%;
    padding: 90px 20px 50px;
  }
  .UXUITopImage {
    height: 250px;
    width: 95%;
  }
  .UXUIh1 {
    font-size: 20px;
  }
  .UXUIh5 {
    font-size: 16px;
  }
  .UXUIPara {
    font-size: 12px;
  }
  /* Section 2 */
  .UXUITopScreen2 {
    min-height: 350px;
    grid-template-columns: 100%;
    justify-content: center;
    align-items: center;
    padding: 50px 20px 0px;
  }
  .UXUITopScreen2Div1 {
    display: grid;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
  }
  .UIandUXdesignh1 {
    font-size: 22px;
  }
  /* Section 3 */
  .UXUITopScreen3 {
    min-height: 400px;
  }
  .CircleDiv1 {
    height: 50px;
    width: 50px;
  }
  .Dot11 {
    height: 50px;
  }
  .UXUITopScreen3Contact {
    padding: 0px 20px 0px;
  }
  .SERVICESh1 {
    font-size: 25px;
  }
  .ServicesPara,
  .Collaborationpara {
    font-size: 14px;
  }
  .UIservicesincludeMainDiv {
    grid-template-columns: 100%;
    margin: 30px 0px 0px;
  }
  .UIservicesincludeDiv {
    width: 100%;
  }
  .Servicesh4,
  .Collaborationh1 {
    font-size: 20px;
  }
  .KeyaspectsofUIdesignh5 {
    font-size: 16px;
  }
  .CollaborationImage {
    height: 700px;
    width: 90%;
    background-size: 380%;
    padding: 50px 20px 50px;
  }
  .WebDesignDiv {
    height: 480px;
  }
}

@media screen and (min-width: 601px) and (max-width: 800px) {
  /* Section 1 */
  .UXUITopScreen {
    min-height: 620px;
    background-size: 250%;
    padding: 120px 30px 50px;
  }
  .UXUITopImage {
    height: 300px;
    width: 95%;
  }
  .UXUIh1 {
    font-size: 25px;
  }
  .UXUIh5 {
    font-size: 18px;
  }
  .UXUIPara {
    font-size: 14px;
  }
  /* Section 2 */
  .UXUITopScreen2 {
    min-height: 350px;
    grid-template-columns: 100%;
    justify-content: center;
    align-items: center;
    padding: 50px 30px 0px;
  }
  .UXUITopScreen2Div1 {
    display: grid;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
  }
  .Talktousbtn {
    margin-bottom: 50px;
  }
  /* Section 3 */
  .UXUITopScreen3 {
    min-height: 400px;
  }
  .CircleDiv1 {
    height: 60px;
    width: 60px;
  }
  .Dot11 {
    height: 60px;
  }
  .UXUITopScreen3Contact {
    padding: 0px 30px 0px;
  }
  .SERVICESh1 {
    font-size: 28px;
  }
  .UIservicesincludeMainDiv {
    grid-template-columns: 100%;
    margin: 30px 0px 0px;
  }
  .UIservicesincludeDiv {
    width: 100%;
  }
  .CollaborationImage {
    height: 680px;
    width: 80%;
    background-size: 280%;
    padding: 50px 30px 520px;
  }
  .Collaborationh1 {
    font-size: 28px;
  }
  .KeyaspectsofUIdesignh5 {
    font-size: 16px;
  }
  .WebDesignDiv {
    display: grid;
    justify-content: center;
    align-items: center;
    height: 480px;
  }
}

@media screen and (min-width: 801px) and (max-width: 1000px) {
  /* Section 1 */
  .UXUITopScreen {
    min-height: 580px;
    background-size: 200%;
    padding: 120px 40px 50px;
  }
  .UXUITopImage {
    height: 350px;
    width: 95%;
  }
  /* Section 2 */
  .UXUITopScreen2 {
    min-height: 400px;
    justify-content: center;
    align-items: center;
    padding: 50px 40px 0px;
  }
  .UXUITopScreen2Div1 {
    display: grid;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
  }
  .UXUITopScreen2Div2 {
    margin-left: 30px;
  }
   /* Section 3 */
   .UXUITopScreen3 {
    min-height: 400px;
  }
  .CircleDiv1 {
    height: 70px;
    width: 70px;
  }
  .Dot11 {
    height: 70px;
  }
  .UXUITopScreen3Contact {
    padding: 0px 40px 0px;
  }
  .SERVICESh1 {
    font-size: 30px;
  }
  .UIservicesincludeMainDiv {
    grid-template-columns: 50% 50%;
    margin: 30px 0px 0px;
  }
  .UIservicesincludeDiv {
    width: 90%;
  }
  .CollaborationImage {
    height: 550px;
    width: 80%;
    background-size: 280%;
    padding: 50px 30px 520px;
  }
  .Collaborationh1 {
    font-size: 28px;
  }
  .WebDesignDiv {
    height: 400px;
  }
}

@media screen and (min-width: 1001px) and (max-width: 1200px) {
  /* Section 1 */
  .UXUITopScreen {
    min-height: 580px;
    background-size: 150%;
  }
  /* Section 2 */
  .CollaborationImage {
    height: 500px;
    width: 80%;
    background-size: 150%;
  }
  
}

@media screen and (min-width: 1201px) and (max-width: 1400px) {
  /* Section 1 */
  /* Section 2 */
  /* Section 3 */
}
