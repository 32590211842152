/* Section 1 */

.ContactTopScreen {
  background-image: linear-gradient(
    to bottom,
    rgba(252, 251, 255, 1),
    rgba(241, 230, 255, 1)
  );
  min-height: 650px;
  width: 100%;
  display: grid;
  grid-template-columns: 50% 50%;
  padding: 160px 80px 50px;
}
.ContactTopScreenDiv1 {
  padding: 0px 80px 20px 0px;
}
.Linediv {
  display: flex;
  margin: 30px 0px 10px;
}
.Line {
  height: 3px;
  width: 10%;
  background: rgba(116, 63, 169, 1);
  margin-top: 20px;
  margin-right: 10px;
  margin-left: 0px;
}

.ScreentopImageDiv {
  background-image: linear-gradient(
    to left,
    rgba(221, 199, 249, 1),
    rgba(241, 231, 255, 0.22),
    rgba(243, 233, 255, 0.18),
    rgba(241, 230, 255, 0.19)
  );
  height: 400px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
}
.ContactTopScreenh1 {
  width: 80%;
  margin: 20px 0px 20px;
}
/* Section 2 */
.ContactTopScreen2 {
  background: #fff;
  min-height: 500px;
  width: 100%;
  padding: 50px 50px 0px;
  text-align: center;
}
.Linediv1 {
  display: flex;
  margin: 20px 0px 0px;
  justify-content: center;
}
.Line1 {
  height: 3px;
  width: 5%;
  background: rgba(116, 63, 169, 1);
  margin-top: 20px;
  margin-right: 10px;
  margin-left: 0px;
}
.ContactdetailsMainDiv {
  display: grid;
  grid-template-columns: 40% 60%;
  margin: 50px 0px 50px;
  text-align: left;
}
.ContactdetailsDiv1 {
  display: flex;
  align-items: center;
}
.contactCardDiv {
  height: 420px;
  width: 70%;
  margin: auto;
  box-shadow: 0px 0px 5px rgba(71, 71, 72, 0.19);
  border-radius: 30px;
}
.contactCardHeader {
  height: 80px;
  width: 100%;
  background: rgba(140, 77, 223, 1);
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.contactCardBody {
  padding: 30px;
}
.LocationDiv {
  display: flex;
  margin-top: 10px;
}
.LocationImage {
  margin: 5px 10px 0px 0px;
}
.tableDiv {
  height: 450px;
  width: 80%;
  margin: auto;
  box-shadow: 0px 0px 5px rgba(71, 71, 72, 0.19);
  border-radius: 30px;
}
table tr {
  display: flex;
  justify-content: space-evenly;
  margin: 10px 0px 10px;
}
.textAreaField {
  border: 1px solid rgba(140, 77, 223, 0.52);
  padding: 5px 10px 5px;
  margin: 5px 5px 5px 0px;
  border-radius: 10px;
}
.inputField {
  border: 1px solid rgba(140, 77, 223, 0.52);
  padding: 5px 10px 5px;
  margin: 5px 0px 0px 0px;
  height: 50px;
  border-radius: 10px;
}
.tableDivtr {
  margin-top: 30px;
}
.submitbtn {
  width: 100%;
  background: #fff;
  padding: 10px 50px 10px;
  border: 1px solid rgba(140, 77, 223, 0.52);
  border-radius: 20px;
  background: rgba(140, 77, 223, 1);
  color: #fff;
}
/* Section 3 */
.ContactTopScreen3 {
  background: #fff;
  min-height: 500px;
  width: 100%;
  text-align: center;
}
.companyLocationImage {
  height: 700px;
  width: 100%;
}

/* Media query for screen sizes */
@media screen and (min-width: 1px) and (max-width: 600px) {
  /* Section 1 */
  .ContactTopScreen {
    min-height: 580px;
    padding: 130px 20px 50px;
  }
  .ScreentopImage {
    height: 200px;
  }
  .ContactTopScreenDiv1 {
    padding: 0px 0px 20px 0px;
  }
  .Lineh5 {
    font-size: 16px;
  }
  .ContactTopScreenh11 {
    font-size: 22px;
  }
  /* Section 2 */
  .ContactTopScreen2 {
    padding: 50px 20px 0px;
  }
  .Leth2 {
    font-size: 23px;
  }
  .ContactdetailsMainDiv {
    grid-template-columns: 100%;
  }
  .ContactdetailsDiv1 {
    margin-bottom: 30px;
  }
  .ContactdetailsDi2 {
    padding: 0px 0px 0px;
    margin: 0px;
  }
  .tableDiv {
    height: 450px;
    width: 100%;
    margin: auto;
    padding: 0px 30px 0px;
  }
  .MessageTD {
    margin-left: 10px;
  }
  .textAreaField {
    padding: 10px;
    width: 95%;
    margin: auto;
    margin-top: 5px;
  }
  .inputField {
    margin: 5px 0px 0px;
  }
  /* Section 3 */
  .ContactTopScreen3 {
    min-height: 300px;
  }
  .companyLocationImage {
    height: 300px;
    width: 100%;
  }
}

@media screen and (min-width: 601px) and (max-width: 800px) {
  /* Section 1 */
  .ContactTopScreen {
    min-height: 580px;
    padding: 130px 30px 50px;
  }
  .ScreentopImage {
    height: 250px;
  }
  .ContactTopScreenDiv1 {
    padding: 0px 0px 20px 0px;
  }
  .Lineh5 {
    font-size: 18px;
  }
  .ContactTopScreenh11 {
    font-size: 28px;
  }
  /* Section 2 */
  .ContactTopScreen2 {
    padding: 50px 30px 0px;
  }
  .Leth2 {
    font-size: 25px;
  }
  .ContactdetailsMainDiv {
    grid-template-columns: 100%;
  }
  .ContactdetailsDiv1 {
    margin-bottom: 30px;
  }
  .ContactdetailsDi2 {
    padding: 0px 0px 0px;
    margin: 0px;
  }
  .tableDiv {
    height: 450px;
    width: 80%;
    margin: auto;
    padding: 0px 30px 0px;
  }
  .MessageTD {
    margin-left: 10px;
  }
  .textAreaField {
    padding: 10px;
    width: 98%;
    margin: auto;
    margin-top: 5px;
  }
  .inputField {
    margin: 5px 0px 0px;
  }
  /* Section 3 */
  .ContactTopScreen3 {
    min-height: 400px;
  }
  .companyLocationImage {
    height: 400px;
    width: 100%;
  }
}

@media screen and (min-width: 801px) and (max-width: 1000px) {
  /* Section 1 */
  .ContactTopScreen {
    min-height: 580px;
    padding: 130px 40px 50px;
  }
  .ScreentopImage {
    height: 300px;
  }
  .ContactTopScreenDiv1 {
    padding: 0px 0px 30px 0px;
  }
  /* Section 2 */
  .ContactTopScreen2 {
    padding: 50px 0px 0px;
  }
  .Leth2 {
    font-size: 25px;
  }
  .ContactdetailsMainDiv {
    grid-template-columns: 40% 60%;
  }
  .contactCardDiv {
    height: 420px;
    width: 90%;
    margin: auto;
  }
  .addressPara {
    font-size: 12px;
  }
  .tableDiv {
    height: 450px;
    width: 50%;
    margin: auto;
  }
  .textAreaField {
    padding: 5px 0px 5px 10px;
    margin: 5px 20px 5px 0px;
    width: 95%;
  }
  .inputField {
    padding: 5px 0px 5px 10px;
    margin: 5px 20px 0px 0px;
    height: 50px;
    width: 180px;
  }
  table td {
    padding-left: 20px;
  }
  /* Section 3 */
  .ContactTopScreen3 {
    min-height: 550px;
  }
  .companyLocationImage {
    height: 550px;
    width: 100%;
  }
}

@media screen and (min-width: 1001px) and (max-width: 1200px) {
  /* Section 1 */

  /* Section 2 */
  .ContactTopScreen2 {
    padding: 50px 50px 0px;
  }
  .Leth2 {
    font-size: 25px;
  }
  .ContactdetailsMainDiv {
    grid-template-columns: 40% 60%;
  }
  .contactCardDiv {
    height: 420px;
    width: 90%;
    margin: auto;
  }
  .tableDiv {
    height: 450px;
    margin-right: 0px;
  }
  .textAreaField {
    padding: 5px 0px 5px 10px;
    margin: 5px 20px 5px 0px;
    width: 95%;
  }
  .inputField {
    padding: 5px 0px 5px 10px;
    margin: 5px 20px 0px 0px;
    height: 50px;
    width: 180px;
  }
  table td {
    padding-left: 20px;
  }
  /* Section 3 */
  .ContactTopScreen3 {
    min-height: 600px;
  }
  .companyLocationImage {
    height: 600px;
    width: 100%;
  }
}

@media screen and (min-width: 1201px) and (max-width: 1400px) {
  /* Section 1 */
  /* Section 2 */
  /* Section 3 */
}
