/* Section 1 */

.WebDevelopmentTopScreen {
  min-height: 500px;
  background-repeat: no-repeat;
  background-size: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 60% 40%;
  padding: 120px 80px 50px;
  color: #fff;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;
}
.WebDevelopmenth5 {
  margin: 20px 0px 20px;
}
.WebDevelopmentTopScreenDiv2 {
  text-align: center;
}
.Letstalkbtn {
  background: #fff;
  padding: 5px 20px 5px;
  color: #000;
  border: 0px;
  border-radius: 30px;
  margin-top: 20px;
}
/* Section 2 */
.WebDevelopmentTopScreen2 {
  background: #fff;
  height: 500;
  width: 100%;
  padding: 50px 80px 50px;
  text-align: center;
}
.LineDiv {
  width: 5%;
  height: 4px;
  background: rgba(116, 63, 169, 1);
  margin: auto;
}
.WebSolutionMaindiv {
  display: grid;
  grid-template-columns: 50% 50%;
  padding: 30px 0px 30px;
  justify-content: center;
  align-items: center;
  margin: 20px 0px 50px;
}
.backTextDiv {
  display: flex;
  justify-content: left;
  align-items: center;
}
.backTextColor {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}
.WebSolutionText {
  text-align: left;
}
.GrowCrafth1 {
  color: rgba(116, 63, 169, 1);
  margin-bottom: 30px;
}
.GrowCraftMainDiv {
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
  margin: 50px 0px 30px;
}
.GrowCraftDiv {
  background-image: linear-gradient(
    to right,
    rgba(237, 108, 248, 0.29),
    rgba(185, 191, 241, 0.13)
  );
  height: 400px;
  width: 90%;
  margin: auto;
  border-radius: 20px;
  margin-bottom: 30px;
  padding: 50px 30px 50px;
}
.GrowCraftPara {
  color: rgba(103, 106, 109, 1);
  font-size: 14px;
}
.GrowCrafth4 {
  margin: 20px 0px 20px;
}
.BoostMainDiv {
  height: 300px;
  width: 100%;
  border-radius: 20px;
  background: rgba(230, 230, 243, 1);
  margin: 50px 0px 20px;
  display: grid;
  grid-template-columns: 60% 40%;
  justify-content: center;
  align-items: center;
}
.BoostDiv1 {
  padding: 0px 50px 0px;
  text-align: left;
}
.ContactBtn {
  padding: 10px 30px 10px;
  background-color: rgba(116, 63, 169, 1);
  border-radius: 30px;
  border: 0px;
  color: #fff;
  margin-top: 20px;
}

/* Media query for screen sizes  */
@media screen and (min-width: 1px) and (max-width: 600px) {
  /* Section 1 */
  .WebDevelopmentTopScreen {
    min-height: 600px;
    background-size: 300%;
    padding: 80px 20px 50px;
  }
  .WebDevelopmenth1 {
    font-size: 25px;
  }
  .WebDevelopmenth5 {
    font-size: 18px;
  }
  .WebDevelopmentPara {
    font-size: 16px;
  }
  .WebDevelopmentTopImage {
    height: 170px;
    width: 170px;
  }
  /* Section 2 */
  .WebDevelopmentTopScreen2 {
    height: 500;
    padding: 50px 20px 50px;
  }
  .WhatWebh1 {
    font-size: 25px;
  }
  .WhatWebPara {
    font-size: 16px;
  }
  .WebSolutionMaindiv {
    grid-template-columns: 100%;
  }
  .WebSolutiondiv212 {
    background-color: rgba(116, 63, 169, 1);
    color: #fff;
    padding: 20px;
    margin-bottom: 20px;
    margin-top: 30px;
    border-radius: 20px;
  }
  .WebSolutionImage {
    height: 300px;
  }
  .LineDiv {
    width: 15%;
  }
  .GrowCraftMainDiv {
    display: grid;
    grid-template-columns: 100%;
  }
  .GrowCraftDiv {
    height: 350px;
  }
  .BoostMainDiv {
    height: 500px;
    margin: 50px 0px 20px;
    display: grid;
    grid-template-columns: 100%;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (min-width: 601px) and (max-width: 800px) {
  /* Section 1 */
  .WebDevelopmentTopScreen {
    min-height: 580px;
    background-size: 180%;
    padding: 50px 30px 0px;
  }
  .WebDevelopmenth1 {
    font-size: 28px;
  }
  .WebDevelopmentTopImage {
    height: 170px;
    width: 220px;
  }
  /* Section 2 */
  .WebDevelopmentTopScreen2 {
    height: 500;
    padding: 50px 30px 50px;
  }
  .WhatWebh1 {
    font-size: 28px;
  }
  .WebSolutionMaindiv {
    grid-template-columns: 100%;
  }
  .WebSolutiondiv212 {
    background-color: rgba(116, 63, 169, 1);
    color: #fff;
    padding: 20px;
    margin-bottom: 20px;
    margin-top: 30px;
    border-radius: 20px;
  }
  .WebSolutionImage {
    height: 330px;
  }
  .LineDiv {
    width: 15%;
  }
  .GrowCraftMainDiv {
    display: grid;
    grid-template-columns: 100%;
  }
  .GrowCraftDiv {
    height: 310px;
  }
  .BoostMainDiv {
    height: 500px;
    margin: 50px 0px 20px;
    display: grid;
    grid-template-columns: 100%;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (min-width: 801px) and (max-width: 1000px) {
   /* Section 1 */
   .WebDevelopmentTopScreen {
    height: 420px;
    background-size: 110%;
    padding: 120px 30px 50px;
  }
  .WebDevelopmentTopImage {
    height: 190px;
    width: 300px;
  }
  /* Section 2 */
  .WebDevelopmentTopScreen2 {
    height: 500;
    padding: 50px 30px 50px;
  }
  .WebSolutionImage {
    height: 330px;
  }
  .LineDiv {
    width: 10%;
  }
  .GrowCraftMainDiv {
    display: grid;
    grid-template-columns: 50% 50%;
  }
  .GrowCraftDiv {
    height: 380px;
  }
  .BoostMainDiv {
    grid-template-columns: 50% 50%;
  }
  .ReadyImage{
    height: 180px;
  }
}

@media screen and (min-width: 1001px) and (max-width: 1200px) {
  /* Section 1 */
  /* Section 2 */
  .WebDevelopmentTopScreen2 {
    padding: 50px 40px 50px;
  }
  .WebSolutionImage {
    height: 380px;
  }
  .GrowCraftDiv {
    height: 450px;
  }
  .ReadyImage{
    height: 200px;
  }
}

@media screen and (min-width: 1201px) and (max-width: 1400px) {
  /* Section 1 */
  /* Section 2 */
}
